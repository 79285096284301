<template>
  <div v-if="!$isMobile">
    <div class="logo">
      <img src="@/assets/img/logo.png" alt="" srcset="">
    </div>
    <div class="background"></div>
    <div class="wrapper">
      <div class="grid-container">
        <div class="Login-In">
          <h1>
            留服搜索SAAS管理系统
          </h1>
          <a-form layout="vertical" :model="form" name="basic" autocomplete="off" @finish="onFinish"
            @finishFailed="onFinishFailed">
            <a-form-item label="用户名" name="username" :rules="[{ required: true, message: '输入用户名' }]">
              <a-input size="large" v-model:value.trim="form.username" placeholder="输入用户名">
                <template #prefix>
                  <user-outlined type="user" style="color:rgba(0,0,0,.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="密码" name="password" :rules="[{ required: true, message: '输入密码' }]">
              <a-input-password size="large" v-model:value.trim="form.password" placeholder="输入密码">
                <template #prefix>
                  <lock-outlined type="lock" style="color:rgba(0,0,0,.25)" />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <div class="btnCon">
                <a href="javascript:;">忘记密码？</a>
                <a-button class="start" block size="large" type="primary" html-type="submit">登录</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="Login-In-Img">
          <div class="img">
            <img src="@/assets/img/bgright.png">
          </div>
        </div>
      </div>
    </div>
    <div class="Copyright">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-5</a></div>
  </div>
  <div class="m-login" v-else>
    <div class="m-logo">
      <img src="@/assets/img/logo.png" alt="" srcset="">
    </div>
    <div class="flex-center">
      <div class="m-grid-container">
        <div class="Login-In">
          <h3>
            留服搜索SAAS管理系统
          </h3>
          <a-form layout="vertical" :model="form" name="basic" autocomplete="off" @finish="onFinish"
            @finishFailed="onFinishFailed">
            <a-form-item label="用户名" name="username" :rules="[{ required: true, message: '输入用户名' }]">
              <a-input size="large" v-model:value.trim="form.username" placeholder="输入用户名">
                <template #prefix>
                  <user-outlined type="user" style="color:rgba(0,0,0,.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="密码" name="password" :rules="[{ required: true, message: '输入密码' }]">
              <a-input-password size="large" v-model:value.trim="form.password" placeholder="输入密码">
                <template #prefix>
                  <lock-outlined type="lock" style="color:rgba(0,0,0,.25)" />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <div class="btnCon">
                <a href="javascript:;" @click="showModal">忘记密码？</a>
                <a-button class="start" block size="large" type="primary" html-type="submit">登录</a-button>
              </div>
            </a-form-item>
          </a-form>
          <a-modal style="height:300px;top: 50%;left: 50%;margin-top: -150px;
  margin-left: -200px;" width='400px' v-model:open="open" title="忘记密码" @ok="handleOk">
            <p class='modal_p'>请联系管理员进行密码重置</p>
            <template #footer>
              <div style="text-align: right;">
                <a-button class='modal_btn' @click="handleOk">确定</a-button>
              </div>
            </template>
          </a-modal>
        </div>
      </div>
    </div>
    <div class="m-copyright">Copyright ©2022-{{ new Date().getFullYear() }} 留服搜索 All Rights Reversed | <a
        href="https://beian.miit.gov.cn">蜀ICP备2022022830号-5</a></div>
  </div>
</template>
<script setup>
import { onMounted, getCurrentInstance } from 'vue';

// Vue3官网中强烈建议不要把它当作在组合式 API 中获取 this 的替代方案来使用。
let internalInstance = getCurrentInstance();
let $http = internalInstance.appContext.config.globalProperties.$http;
let $message = internalInstance.appContext.config.globalProperties.$message;
let $router = internalInstance.appContext.config.globalProperties.$router;
onMounted(() => {
  //console.log(internalInstance.appContext.config.globalProperties);		// globalName

})

import { reactive } from 'vue';
const form = reactive({
  username: '',
  password: '',
});
const onFinish = async values => {
  //console.log('Success:', values);
  try {
    let res = await $http.post('/auth/login', {
      ...form
    });
    let isHome = res.home
    $message.success('登录成功');
    if (isHome) {
      await $router.push('/dataReport/dataGlance');
    } else {
      await $router.push('/');
    }

  } catch ({ message }) {
    $message.error(message);
  }
};
const onFinishFailed = errorInfo => {
  //console.log('Failed:', errorInfo);
};

</script>
<style lang="scss" scoped>
.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: url("~@/assets/img/bg.png") center center;
}

.Login-In {
  position: relative;
  text-align: center;
  padding: 50px;
  color: #2f3640;
  background: white;
  overflow: hidden;
  min-width: 275px;
}

.Login-In h1 {
  font-size: 18pt;
  font-weight: bold;
  margin-bottom: 30px;
  color: #2E317D;
}

.Login-In form {
  margin: 50px 0 50px 0;
}

.Login-In form input {
  height: 40px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #2E317D;
}

.Login-In button {
  width: auto;
  display: block;
  outline: none;
}

.Login-In button.start {
  padding: 0px 40px;
  background: #2E317D;
}

.Login-In-Img {
  position: relative;
  overflow: hidden;
  min-width: 275px;
}

.Login-In-Img .img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.Login-In-Img .img img {
  height: 100%;
  width: 100%;
}


.Login-In .input {
  position: relative;
}

.Login-In input {
  font-size: 10pt;
  padding-left: 20px;
  display: block;
  width: 80%;
  border: none;
}

.Login-In form label {
  display: block;
  text-align: left;
  margin: 30px 0 15px 0;
  color: #2E317D;
}

.wrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-template-areas: ". a a ." ". a a .";
}

.grid-container {
  grid-area: a;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "Login-In Login-In-Img";
  height: 485px;
  width: 750px;
}

.Login-In {
  grid-area: Login-In;
}

.Login-In-Img {
  grid-area: Login-In-Img;
  background: white;
}

.logo {
  position: absolute;
  top: 30px;
  left: 40px;
}

.logo img {
  width: 120px;
  height: 60px;
}

.btnCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.btnCon a {
  text-decoration: none;
  color: #2E317D;
}

.Copyright {
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
}

.Copyright a {
  text-decoration: none;
  color: #fff;
}

.ant-input-affix-wrapper {
  border-color: #2E317D;
}

:deep(.ant-form-item .ant-form-item-label>label) {
  color: #2E317D;
}

:deep(.ant-form-vertical .ant-form-item-label) {
  padding-bottom: 15px;
  color: #2E317D;
}

:deep(.ant-form-item .ant-form-item-explain-error) {
  text-align: left;
}

.m-login {
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: url("~@/assets/img/bg.png") center center;
}

.m-logo {
  padding: 20px;

  img {
    width: 100px;
  }
}

.m-grid-container {
  grid-area: a;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "Login-In Login-In-Img";
  height: 400px;
  width: 280px;
  margin-left: calc(50% - 160px);
  margin-top: 40px;

  .Login-In {
    position: relative;
    text-align: center;
    padding: 30px 20px;
    color: #2f3640;
    background: white;
    overflow: hidden;
    border-radius: 8px;
  }

  .Login-In h3 {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 30px;
    color: #2E317D;
  }

  .Login-In form {
    margin: 30px 0 50px 0;
  }

  .Login-In form input {
    height: 40px;
    width: 100px;
    border-radius: 12px;
    border: 1px solid #2E317D;
  }

  .Login-In button {
    width: auto;
    display: block;
    outline: none;
  }

  .Login-In button.start {
    padding: 0px 40px;
    background: #2E317D;
  }

  .Login-In .input {
    position: relative;
  }

  .Login-In input {
    font-size: 10pt;
    padding-left: 20px;
    display: block;
    width: 80%;
    border: none;
  }

  .Login-In form label {
    display: block;
    text-align: left;
    margin: 30px 0 15px 0;
    color: #2E317D;
  }
}

.m-copyright {
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
  padding: 0 30px;
  font-size: 12px;

  a {
    text-decoration: none;
    color: #fff;
  }
}
</style>
