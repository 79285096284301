export default {
  title: '院校库',
  key: '/college/college',
  permission: '/school/list',
  config: {
    component: () => import("./views/college"),
    actions: [
      {
        text: '新建院校',
        permission: '/school/add',
      },
      {
        text: '院校库-专业-列表',
        permission: '/professionalCollegeRelation/list',
      },
      {
        text: '院校库-专业-新建专业',
        permission: '/professionalCollegeRelation/add',
      }
    ],
    rowActions: [
      {
        text: '编辑院校',
        permission: '/school/update',
      },
      {
        text: '申请参考',
        permission: '/school/apply',
      },
      {
        text: '院校库-专业-编辑',
        permission: '/professionalCollegeRelation/update',
      },
      {
        text: '院校库-专业-删除',
        permission: '/professionalCollegeRelation/delete',
      }
    ]
  }
}

