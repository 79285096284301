export default {
  key: '/agency/serviceItems',
  title: '服务项目',
  permission: '/clientUser/serviceProject/getList',
  permissionTit: '服务项目',
  config: {
    component: () => import("./views/serviceItemsDetail"),
    actions: [
      {
        text: '新建服务项目',
        permission: '/clientUser/serviceProject/insert',
      },
      {
        text: '删除',
        permission: '/clientUser/serviceProject/delete',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/clientUser/serviceProject/update',
      }
    ]
  }
} 