export default {
  key: '/agency/storeManage',
  title: '门店管理',
  permission: '/clientUser/institutionalShop/getList',
  config: {
    component: () => import("./views/storeManageDetail"),
    actions: [
      {
        text: '留学机构-列表',
        permission: '/clientUser/institutionalShop/getList',
      },
      {
        text: '留学机构-新建机构门店',
        permission: '/clientUser/institutionalShop/add',
      },
      {
        text: '预约福利-列表',
        permission: '/benefit/getList',
      },
      {
        text: '预约福利-新建',
        permission: '/benefit/insert',
      },
      {
        text: '预约福利-编辑福利介绍',
        permission: '/benefit/editBenefitPicture',
      },
      {
        text: '预约福利-删除',
        permission: '/benefit/delete',
      },
      {
        text: '服务项目-列表',
        permission: '/clientUser/serviceProject/getList',
      },
      {
        text: '服务项目-新建',
        permission: '/clientUser/serviceProject/insert',
      },
      {
        text: '服务项目-删除',
        permission: '/clientUser/serviceProject/delete',
      },
      {
        text: '服务团队-列表',
        permission: '/clientUser/serviceTeam/getList',
      },
      {
        text: '服务团队-新建',
        permission: '/clientUser/serviceTeam/insert',
      },
      {
        text: '服务团队-删除',
        permission: '/clientUser/serviceTeam/delete',
      },
      {
        text: '留学机构-荣誉资质-列表',
        permission: '/clientUser/honorAndQualification/getList',
      },
      {
        text: '留学机构-荣誉资质-新建',
        permission: '/clientUser/honorAndQualification/insert',
      },
      {
        text: '留学机构-荣誉资质-删除',
        permission: '/clientUser/honorAndQualification/delete',
      },
      {
        text: '录取案例-列表',
        permission: '/clientUser/caseManagement/getList',
      },
      {
        text: '录取案例-新建',
        permission: '/clientUser/caseManagement/insert',
      },
      {
        text: '录取案例-删除',
        permission: '/clientUser/caseManagement/delete',
      },
      {
        text: '用户评价-列表',
        permission: '/clientUser/userEvaluation/getList',
      },
      {
        text: '用户评价-新建',
        permission: '/clientUser/userEvaluation/insert',
      },
      {
        text: '用户评价-删除',
        permission: '/clientUser/userEvaluation/delete',
      },
      {
        text: '国际学校-列表',
        permission: '/clientUser/internationalSchool/list',
      },
      {
        text: '国际学校-新建国际学校门店',
        permission: '/clientUser/internationalSchool/add',
      },
      {
        text: '招生项目-列表',
        permission: '/clientUser/internationalSchool/enrollmentProgram/list',
      },
      {
        text: '招生项目-新建',
        permission: '/clientUser/internationalSchool/enrollmentProgram/add',
      },
      {
        text: '招生项目-删除',
        permission: '/clientUser/internationalSchool/enrollmentProgram/delete',
      },
      {
        text: '国际学校-荣誉资质-列表',
        permission: '/clientUser/internationalSchool/honorQualification/list',
      },
      {
        text: '国际学校-荣誉资质-新建',
        permission: '/clientUser/internationalSchool/honorQualification/add',
      },
      {
        text: '国际学校-荣誉资质-删除',
        permission: '/clientUser/internationalSchool/honorQualification/delete',
      },
      {
        text: '攻略资讯-列表',
        permission: '/article/list',
      },
      {
        text: '攻略资讯-新建',
        permission: '/article/add',
      },
      {
        text: '攻略资讯-删除',
        permission: '/article/delete',
      },
    ],
    rowActions: [
      {
        text: '留学机构-编辑',
        permission: '/clientUser/institutionalShop/update',
      },
      {
        text: '留学机构-复制门店',
        permission: '/clientUser/institutionalShop/copySave',
      },
      {
        text: '预约福利-编辑',
        permission: '/benefit/edit',
      },
      {
        text: '服务项目-编辑',
        permission: '/clientUser/serviceProject/update',
      },
      {
        text: '服务团队-编辑',
        permission: '/clientUser/serviceTeam/update',
      },
      {
        text: '留学机构-荣誉资质-编辑',
        permission: '/clientUser/honorAndQualification/update',
      },
      {
        text: '录取案例-编辑',
        permission: '/clientUser/caseManagement/update',
      },
      {
        text: '用户评价-编辑',
        permission: '/clientUser/userEvaluation/update',
      },
      {
        text: '国际学校-编辑',
        permission: '/clientUser/internationalSchool/edit',
      },
      {
        text: '招生项目-编辑',
        permission: '/clientUser/internationalSchool/enrollmentProgram/edit',
      },
      {
        text: '招生项目-停用',
        permission: '/clientUser/internationalSchool/enrollmentProgram/lock',
      },
      {
        text: '招生项目-启用',
        permission: '/clientUser/internationalSchool/enrollmentProgram/unlock',
      },
      {
        text: '国际学校-荣誉资质-编辑',
        permission: '/clientUser/internationalSchool/honorQualification/edit',
      },
      {
        text: '攻略资讯-编辑',
        permission: '/article/edit',
      },
    ]
  }
}