import { intentionLevel, customerStatus } from '@/utils/public';
export default {
  title: '已分配客资',
  type: 'table',
  key: '/newCustomer/allocationCustomer',
  permission: '/agency/allocationCustomer/list',
  config: {
    dataUrl: '/agency/allocationCustomer/list',
    filter: {
      initUrl: '/agency/allocationCustomer/list/get',
      controls: [
        {
          key: 'institutionsCustomerNameContains',
          label: '留服客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'staffPersonIdIs',
          label: '客服',
          type: 'select',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'institutionsIdIs',
          label: '所属机构',
          type: 'souSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'createdDate',
          label: '创建时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'phoneNumberContains',
          label: '手机号',
          type: 'text',
          config: {
            rules: 'phoneNumber'
          }
        },
        {
          key: 'wechatContains',
          label: '微信号',
          type: 'text',
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'select',
          config: {
            options: intentionLevel
          }
        },
        {
          key: 'customerStatusIs',
          label: '客户状态',
          type: 'select',
          config: {
            options: customerStatus
          }
        },
        {}
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    columns: [
      {
        key: 'institutionsCustomer.name',
        width: '150px',
        title: '留服客户姓名'
      },
      {
        key: 'attributablePerson.name',
        width: '120px',
        title: '客资归属人'
      },
      {
        key: 'staffPerson.name',
        title: '客服',
        width: 100,
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '所属机构'
      },
      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'name',
        width: '130px',
        title: '客户姓名'
      },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: '100px',
      },
      {
        key: 'wechat',
        title: '微信号',
        width: '100px',
      },
      {
        key: 'intentionLevel',
        title: '意向等级',
        type: 'enum',
        width: '80px',
        align: "center",
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '',
              color: ''
            }
          }
        }
      },
      // {
      //   key: 'userLabelList',
      //   title: '客户标签',
      //   width: 280,
      //   type: 'color',
      //   config: {
      //     textkey: 'content',
      //     colorkey: 'color'
      //   }
      // },
      {
        key: 'attribution.name',
        width: '120px',
        title: '归属人'
      },
      {
        key: 'followUpWithPeople.name',
        width: '120px',
        title: '跟进人'
      },
      {
        key: 'customerStatus',
        title: '客户状态',
        type: 'enum',
        width: '80px',
        align: 'center',
        config: {
          constants: {
            TO_BE_FOLLOWED_UP: {
              text: '待跟进',
              color: 'orange'
            },
            IN_FOLLOW_UP: {
              text: '跟进中',
              color: 'green'
            },
            HAS_BECOME_A_SINGLE: {
              text: '已成单',
              color: 'cyan'
            },
            MULTIPLE_ORDERS: {
              text: '多次成单',
              color: 'red'
            },
            ACCURATE_CUSTOMER_FUNDING: {
              text: '精准客资',
              color: 'red'
            }
          }
        }
      },
      {
        key: 'numberOfFollowUps',
        title: '跟进次数',
        width: '80px',
        align: 'center',
      },
      {
        key: 'institutionsFollowUp.followUpContent',
        title: '最近跟进情况',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'institutionsFollowUp.nextFollowUpTime',
        title: '下次跟进时间',
        type: 'datetime',
        width: '110px',
      },
      {
        key: 'remarks',
        title: '其他信息',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '120px'
      }
    ],
    actions: [

    ],
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/allocationCustomer/details',
        config: {
          title: '客户详情',
          component: () => import("./agencyviews/agencyCusDetail")
        }
      },
      {
        key: 'add',
        text: '成单',
        icon: 'plus',
        type: 'form',
        permission: '/agency/allocationCustomer/addOrder',
        config: {
          color: 'primary',
          title: '成单',
          initUrl: '/agency/allocationCustomer/addOrder/get',
          submitUrl: '/agency/allocationCustomer/addOrder',
          submitText: '确认成单',
          setData: true,
          secondVerify: '添加后订单也会生成在机构CRM系统，该操作不可逆，请慎重。确定要添加订单吗？',
          controls: [
            {
              key: 'institutionName',
              label: '所属机构',
              type: 'text',
              disabled: true,
            },
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'attributionId',
              label: '订单归属人',
              type: 'select',
              tips: '机构CRM中，客户的归属人',
              disabled: true,
              config: {
                options: "attributablePerson",
              }
            },
            {
              key: 'followUpWithPeopleId',
              label: '订单跟进人',
              type: 'select',
              tips: '机构CRM中，客户的跟进人',
              disabled: true,
              config: {
                options: "followUpPeople",
              }
            },
            {
              key: 'signingTime',
              label: '签单时间',
              type: 'date',
              disabledDate: true,
              showNow: true,
              required: true,
            },
            {
              key: 'serviceItemId',
              label: '服务项目',
              type: 'select',
              required: true,
              config: {
                options: "signingItemList",
                textKey: 'content'
              }
            },
            {
              key: 'orderAmount',
              label: '订单金额',
              type: 'numeric',
              required: true,
              config: {
                format: 'currency',
                max: (form) => { }
              }
            },
            {
              key: 'orderStatus',
              label: '订单情况',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
}

