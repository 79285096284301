<template>
  <a @click="showDrawer">
    推广链接
    <a-modal v-model:open="drawerVisible" title="推广链接" @ok="drawerVisible = false" @cancel="hideDrawer()">
      <p style="margin-bottom: 20px;">客户或机构通过该链接访问留服搜索，成功预约机构或申请入驻留服搜索，都会将您设置为相关归属人</p>
      <div style="margin-bottom: 6px;">
        <span>我的客户推广链接:</span>
        <a-popover title="">
          <template #content>
            <p>客户成功预约或提交表单，可在【全部客资】查看</p>
          </template>
          <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
        </a-popover>
      </div>
      <div style="margin-bottom: 10px;">
        <span ref="sousuo">https://www.liufusousuo.com/?ref={{ info.ref }}</span>
        <span @click="copyToClipboard('sousuo')" style="color: #1677ff; cursor: pointer; margin-left: 20px;">复制</span>
      </div>
      <div style="margin-bottom: 10px;">
        <a-image style="width: 100px; height: 100px; object-fit: cover;" :src="info.customerQRCode" />
      </div>
      <div style="margin-bottom: 6px;">
        <span>我的机构推广链接:</span>
        <a-popover title="">
          <template #content>
            <p>机构注册或申请入驻，可在【申请机构】查看</p>
          </template>
          <QuestionCircleOutlined style="color: #999999; margin-left: 4px;" />
        </a-popover>
      </div>
      <div style="margin-bottom: 10px;">
        <span ref="crm">https://www.liufucrm.com/?ref={{ info.ref }}</span>
        <span @click="copyToClipboard('crm')" style="color: #1677ff; cursor: pointer; margin-left: 20px;">复制</span>
      </div>
      <div style="margin-bottom: 20px;">
        <a-image style="width: 100px; height: 100px; object-fit: cover;" :src="info.institutionQRCode" />
      </div>
      <p style="margin-bottom: 6px;">推广后缀:</p>
      <div>
        <span>ref= </span>
        <a-input v-model:value="ref" :maxlength="8" style="width: 50px"></a-input>
        <span @click="submit()" style="color: #1677ff; cursor: pointer; margin-left: 20px;">保存</span>
      </div>
      <template #footer>
        <div style="text-align: center;">
          <a-button type="primary" @click="hideDrawer()">好的</a-button>
        </div>
      </template>
    </a-modal>
  </a>
</template>

<script>

export default {
  name: "RecommendationCode",
  data() {
    return {
      drawerVisible: false,
      ref: '',
      info: {}
    }
  },
  mounted() {

  },
  methods: {
    showDrawer() {
      this.refInfo();
      this.drawerVisible = true;
    },

    hideDrawer() {
      this.drawerVisible = false;
      this.ref = '';
    },

    async refInfo() {
      try {
        let res = await this.$http.get('/system/employee/editRef/get');
        this.info = res.source;
        this.ref = res.source.ref;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async submit() {
      if (!this.ref) {
        this.$message.error('请输入推广后缀');
        return
      }
      try {
        await this.$http.post('/system/employee/editRef', {
          ref: this.ref
        });
        this.$message.success('保存成功');
        this.refInfo();
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    copyToClipboard(val) {
      const contentToCopy = this.$refs[val];
      const range = document.createRange();
      range.selectNode(contentToCopy);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      try {
        document.execCommand('copy');
        this.$message.success('内容已成功复制到粘贴板');
      } catch (e) {
        console.error('复制失败', e);
      }
      selection.removeAllRanges();
    },
  }
}
</script>

<style scoped></style>
