
export default {
  key: '/message/manual',
  title: '手动推送管理',
  permission: '/message/manualPush/list',
  config: {
    component: () => import("./views/manualDetail"),
    actions: [
      {
        text: '新建',
        permission: '/message/manualPush/add',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/message/manualPush/edit',
      },
      {
        text: '发送测试',
        permission: '/message/manualPush/sendMessageTest',
      },
      {
        text: '推送',
        permission: '/message/manualPush/sendMessage',
      },
      {
        text: '删除',
        permission: '/message/manualPush/delete',
      },
      {
        text: '查看',
        permission: '/message/manualPush/getDetailById',
      },
    ]
  }

}
