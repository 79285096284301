
export default {
  key: '/message/system',
  title: '系统触发管理',
  permission: '/message/systemTrigger/list',
  config: {
    component: () => import("./views/systemDetail"),
    actions: [
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/message/systemTrigger/update',
      },
      {
        text: '启用',
        permission: '/message/systemTrigger/enable',
      },
      {
        text: '禁用',
        permission: '/message/systemTrigger/disable',
      },
    ]
  }

}
