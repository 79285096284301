export default {
    key: '/dataReport/customerServiceData',
    title: '客服员工数据',
    type: 'table',
    permission: '/report/staff/list',
    config: {
        dataUrl: '/report/staff/list',
        summary: true,
        sticky: true,
        pagination: false,
        scroll: { x: 1200 },
        filter: {
            // initUrl: '/report/market/list/get',
            controls: [
                {
                    key: 'time',
                    label: '时间',
                    type: 'dateRange',
                    resetDisabled: true,
                    config: {
                      initialValue: '当月',
                    }
                  },
            ]
        },
        columns: [
            {
                key: 'username',
                title: '姓名',
                width: '150px',
            },
            {
                key: 'numberOfCharge',
                title: '负责客资',
                width: '150px',
                popover: '时间维度：客资创建时间',
                summary: true,
            },
            {
                key: 'numberOfAllocate',
                title: '分配客资',
                width: '150px',
                summary: true,
                popover: '时间维度：客资创建时间',
            },
            {
                key: 'numberOfAllocateOrg',
                title: '分配机构(家)',
                popover: '时间维度：客资创建时间',
                width: '150px',
                summary: true,
            },
            {
                key: 'orgSignedNum',
                title: '机构签单数(单)',
                popover: '时间维度：客资创建时间',
                width: '150px',
                summary: true,
            },
            {
                key: 'orgSignedOrderAmount',
                title: '机构签单额(元)',
                popover: '时间维度：签单时间',
                width: '150px',
                summary: true,
                type: 'numeric',
                config: {
                    format: 'currency'
                }
            },
            {
                key: 'orgSignedPaidAmount',
                title: '已返金额(元)',
                popover: '时间维度：签单时间',
                width: '150px',
                summary: true,
                type: 'numeric',
                config: {
                    format: 'currency'
                }
            }
        ],

        /* 表格排序 */
        sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
        },
        actions: [
            {
                key: 'chapter',
                text: '导出为Excel',
                type: 'url',
                permission: '/report/staff/export',
                config: {
                    title: '导出为Excel',
                    color: 'primary',
                },
                options: {
                    url: '/api/report/staff/export',
                    params: true,
                    newPage: true
                }
            },
        ],
        /* 表格行操作 */
        rowActions: [
        ]
    }
};
