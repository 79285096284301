
import { gender, serviceItems, hignEducation, languageType, intentionLevel, customerStage, customerStageNew, country, customerSource } from '@/utils/public';
export default {
  title: '待分配客资',
  type: 'table',
  key: '/customer/toBeAllocated',
  permission: '/retention/retentionClients/list',
  config: {
    dataUrl: '/retention/retentionClients/list',
    filter: {
      //initUrl: '/agency/institutionsCustomer/list/get',
      controls: []
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    checkbox: true,
    columns: [
      {
        key: 'createdDate',
        title: '留资时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'customerName',
        title: '客户姓名',
        width: '100px',
      },
      {
        key: 'mobilePhoneNumber',
        title: '手机号',
        width: '150px',
      },
      {
        key: 'consultingProject',
        title: '咨询项目',
        width: '100px',
      },
      {
        key: 'intendedCountries',
        title: '意向国家',
        width: '100px',
      },
      {
        key: 'sourceChannel',
        title: '客户来源',
        width: '150px'
      },
      {
        key: 'otherInformation',
        title: '其他信息',
        width: '100px',
        ellipsis: true
      }
    ],
    actions: [
      {
        key: 'edit',
        text: '领取客户',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/retention/retentionClients/receiveCustomer',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 条客资，是否领取？`
        },
        config: {
          title: '领取客资',
          submitUrl: '/retention/retentionClients/receiveCustomer',
          submitText: '确认',
          controls: [

          ]
        }
      },
      {
        key: 'edit',
        text: '分配客户',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/retention/retentionClients/assignCustomers',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 条客资，请选择客资归属人`
        },
        config: {
          title: '分配客资',
          initUrl: '/retention/retentionClients/assignCustomers/get',
          submitUrl: '/retention/retentionClients/assignCustomers',
          submitText: '确认',
          controls: [
            {
              key: 'attributablePersonId',
              label: '客资归属人',
              type: 'souSelect',
              required: true,
              config: {
                options: "employeesList"
              }
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '删除客资',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/retention/retentionClients/delete',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 条客资，是否删除？`
        },
        config: {
          title: '删除客资',
          submitUrl: '/retention/retentionClients/delete',
          submitText: '确认',
          controls: [

          ]
        }
      },
    ],
    rowActions: [

    ]
  }
}
