export default {
  key: '/agency/institutionsEmployee',
  title: '账号管理',
  type: 'table',
  permission: '/agency/institutionsEmployee/list',
  config: {
    /* 表格是否可多选 */
    dataUrl: '/agency/institutionsEmployee/list',
    filter: {
      initUrl: '/agency/institutionsEmployee/list/get',
      controls: [
        {
          key: 'usernameContains',
          label: '用户名',
          type: 'text'
        },
        {
          key: 'nameContains',
          label: '姓名',
          type: 'text'
        },
        {
          key: 'institutionsIdIs',
          label: '所属机构',
          type: 'souSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {}
      ]
    },
    columns: [
      // {
      //   key: 'username',
      //   title: '用户名',
      //   width: '150px',
      // },
      {
        key: 'name',
        title: '姓名',
        width: '100px',
      },
      {
        key: 'phoneNumber',
        title: '手机',
        width: '100px',
        config: {
          desensitization: 'phoneNumber'
        }
      },
      {
        key: 'institutions.name',
        title: '所属机构',
        width: '150px',
      },
      {
        key: 'createdDate',
        title: '注册时间',
        type: 'datetime',
        width: '100px',
      },

      {
        key: 'locked',
        title: '状态',
        type: 'enum',
        width: '65px',
        align: "center",
        config: {
          constants: {
            false: {
              text: '启用',
              color: 'orange'
            },
            true: {
              text: '停用',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'remarks',
        title: '备注',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '100px' : '180px'
      }

    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    actions: [
      // {
      //   key: 'add',
      //   text: '添加',
      //   icon: 'PlusOutlined',
      //   type: 'form',
      //   permission: '/agency/institutionsEmployee/add',
      //   config: {
      //     color: 'primary',
      //     title: '添加账号',
      //     initUrl: '/agency/institutionsEmployee/add/get',
      //     submitUrl: '/agency/institutionsEmployee/add',
      //     submitText: '确认添加',
      //     controls: [
      //       {
      //         key: 'username',
      //         label: '用户名',
      //         type: 'text',
      //         required: true,
      //         config: {
      //           rules: [
      //             { min: 1, max: 50, message: '只能输入1-50个字符' }
      //           ]
      //         }
      //       },
      //       {
      //         key: 'name',
      //         label: '姓名',
      //         type: 'text',
      //         required: true,
      //         config: {
      //           rules: [
      //             { min: 1, max: 50, message: '只能输入1-50个字符' }
      //           ]
      //         }
      //       },
      //       {
      //         key: 'phoneNumber',
      //         label: '手机号',
      //         type: 'text',
      //         config: {
      //           rules: ['phoneNumber']
      //         }
      //       },
      //       {
      //         key: 'institutionsId',
      //         label: '所属机构',
      //         type: 'select',
      //         required: true,
      //         config: {
      //           options: 'institutionsList'
      //         }
      //       },
      //       {
      //         key: 'remarks',
      //         label: '备注',
      //         type: 'textarea',
      //         required: true,
      //         config: {
      //           rules: [
      //             { min: 1, max: 500, message: '只能输入1-500个字符' }
      //           ]
      //         }
      //       },

      //     ]
      //   }
      // }
    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑账号',
        type: 'form',
        permission: '/agency/institutionsEmployee/edit',
        config: {
          color: 'primary',
          title: '编辑账号',
          initUrl: "/agency/institutionsEmployee/edit/get",
          submitUrl: "/agency/institutionsEmployee/edit",
          submitText: '确认修改',
          controls: [
            {
              key: 'username',
              label: '用户名',
              type: 'text',
              required: true,
              //disabled: true,
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
            {
              key: 'name',
              label: '姓名',
              type: 'text',
              required: true,
              //disabled: true,
              config: {
                rules: [
                  { min: 1, max: 50, message: '只能输入1-50个字符' }
                ]
              }
            },
            {
              key: 'phoneNumber',
              label: '手机号',
              type: 'text',
              disabled: true,
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'institutionsId',
              label: '所属机构',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'remarks',
              label: '备注',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },

          ]
        }
      },
      {
        key: 'reset-password',
        text: '重置密码',
        type: 'confirm',
        permission: '/agency/institutionsEmployee/reset-password',
        disabled: true,
        config: {
          color: 'danger',
          title: '确定对该条记录进行重置密码操作吗？',
          submitUrl: '/agency/institutionsEmployee/reset-password',
          submitText: '确认',
          submitColor: 'danger',
          submitSuccessMessage: '重置密码成功，默认密码：000000',
          submitLoadingMessage: '正在重置密码 ...'
        }
      },
      // {
      //   key: 'lock',
      //   text: '停用',
      //   type: 'confirm',
      //   permission: '/agency/institutionsEmployee/lock',
      //   disabled({ selectedRows }) {
      //     return selectedRows[0].locked === false;
      //   },
      //   config: {
      //     color: 'danger',
      //     submitColor: 'danger',
      //     submitUrl: '/agency/institutionsEmployee/lock'
      //   }
      // },
      // {
      //   key: 'unlock',
      //   text: '恢复',
      //   type: 'confirm',
      //   permission: '/agency/institutionsEmployee/unlock',
      //   disabled({ selectedRows }) {
      //     return selectedRows[0].locked === true;
      //   },
      //   config: {
      //     color: 'danger',
      //     submitColor: 'danger',
      //     submitUrl: '/agency/institutionsEmployee/unlock'
      //   }
      // },
    ]
  }
};
