import { receptionCity, country } from '@/utils/public';
export default {
  key: '/agency/diyService',
  title: 'DIY服务',
  type: 'table',
  permission: '/agency/institutionsEmployee/list',
  config: {
    /* 表格是否可多选 */
    dataUrl: '/agency/institutionsEmployee/list',
    filter: {
      initUrl: '/agency/institutionsEmployee/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '服务标题',
          type: 'text'
        },
        {
          key: 'city',
          label: '目标国家',
          type: 'select',
          config: {
            options: country
          }
        },
        {
          key: 'city',
          label: 'DIY服务分类',
          type: 'select',
          config: {
            options: country
          }
        },
        {}
      ]
    },
    columns: [
      {
        key: 'name',
        title: '服务标题',
        width: '100px',
      },
      {
        key: 'name',
        title: '目标国家',
        width: '100px',
      },
      {
        key: 'name',
        title: 'DIY服务分类',
        width: '100px',
      },
      {
        key: 'name',
        title: '是否热门',
        width: '100px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '预约数',
        width: '100px',
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '100px'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    checkbox: true,
    actions: [
      {
        key: 'edit',
        text: '新建DIY服务',
        type: 'form',
        permission: '/agency/institutionsEmployee/edit',
        config: {
          color: 'primary',
          title: '新建DIY服务',
          // initUrl: "/agency/institutionsEmployee/edit/get",
          submitUrl: "/agency/institutionsEmployee/edit",
          submitText: '保存',
          controls: [
            {
              key: 'paymentVoucher',
              label: '封面图片',
              tips: "建议上传像素为 604x341；大小不超过500kb的图片",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'username',
              label: '用户名',
              type: 'text',
              required: true,
            },
            {
              key: 'city',
              label: '目标国家',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'city',
              label: 'DIY服务分类',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'username',
              label: '服务简介',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 114, message: '请尽量精简内容，输入114字以内' }
                ]
              }
            },
            {
              key: 'city',
              label: '相关机构',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'city',
              label: '相关院校',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'home',
              label: '是否热门',
              type: 'radio',
              required: true,
              config: {
                initialValue: false,
                options: [
                  {
                    text: '否',
                    value: false
                  },
                  {
                    text: '是',
                    value: true
                  },
                ]
              }
            },
            {
              key: 'asd',
              label: '服务详情',
              type: 'editor',
              required: true,
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload'
              }
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '删除',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/institutions/audit/pass',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 条服务项目，是否全部删除? `
        },
        config: {
          title: '删除成功',
          submitUrl: '/institutions/audit/pass',
          submitText: '确认',
          controls: []
        }
      },
    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/agency/institutionsEmployee/edit',
        config: {
          color: 'primary',
          title: '编辑',
          // initUrl: "/agency/institutionsEmployee/edit/get",
          submitUrl: "/agency/institutionsEmployee/edit",
          submitText: '保存',
          controls: [
            {
              key: 'paymentVoucher',
              label: '封面图片',
              tips: "建议上传像素为 604x341；大小不超过500kb的图片",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'username',
              label: '用户名',
              type: 'text',
              required: true,
            },
            {
              key: 'city',
              label: '目标国家',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'city',
              label: 'DIY服务分类',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'username',
              label: '服务简介',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 114, message: '请尽量精简内容，输入114字以内' }
                ]
              }
            },
            {
              key: 'city',
              label: '相关机构',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'city',
              label: '相关院校',
              type: 'select',
              required: true,
              config: {
                options: country
              }
            },
            {
              key: 'home',
              label: '是否热门',
              type: 'radio',
              required: true,
              config: {
                initialValue: false,
                options: [
                  {
                    text: '否',
                    value: false
                  },
                  {
                    text: '是',
                    value: true
                  },
                ]
              }
            },
            {
              key: 'asd',
              label: '服务详情',
              type: 'editor',
              required: true,
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload'
              }
            },
          ]
        }
      },
      {
        key: 'reset-password',
        text: '排序',
        type: 'confirm',
        permission: '/agency/institutionsEmployee/reset-password',
        disabled: true,
        config: {
          color: 'danger',
          title: '确定对该条记录进行重置密码操作吗？',
          submitUrl: '/agency/institutionsEmployee/reset-password',
          submitText: '确认',
          submitColor: 'danger',
          submitSuccessMessage: '重置密码成功，默认密码：000000',
          submitLoadingMessage: '正在重置密码 ...'
        }
      },
    ]
  }
};
