import institutionsCustomer from "./institutionsCustomer";//1
import visitCustomer from "./visitCustomer";//客户回访（隐藏）
import allocationCustomer from "./allocationCustomer"//2
import followRecord from "./followRecord"//4
import highSeas from "./highSeas"//5
import accurateCustomer from './accurateCustomer'
export default [
  institutionsCustomer,
  visitCustomer,
  allocationCustomer,
  followRecord,
  highSeas,
  accurateCustomer
];
