<template>
  <a @click="showDrawer">
    修改密码
    <a-modal v-model:open="drawerVisible" title="修改密码" @ok="submit">
      <a-form layout="vertical" ref="form" :rules="rules" :model="form" @finish="submit">
        <a-form-item label="原密码" name="originalPassword">
          <a-input-password v-model:value="form.originalPassword" placeholder="输入原密码" />
        </a-form-item>
        <a-form-item label="新密码" name="newPassword">
          <a-input-password v-model:value="form.newPassword" placeholder="输入新密码" />
        </a-form-item>
        <a-form-item label="确认新密码" name="confirmPassword">
          <a-input-password v-model:value="form.confirmPassword" placeholder="确认新密码" />
        </a-form-item>
      </a-form>
    </a-modal>
  </a>
</template>

<script>
import { clearProfile } from "@/utils/session";

export default {
  name: "ChangePassword",
  data() {
    return {
      drawerVisible: false,
      submitLoading: false,
      form: {
        originalPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        originalPassword: [
          { required: true, message: '请输入原密码' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码' },
          {
            validator: (rule, value) => {
              if (this.form.newPassword !== this.form.confirmPassword) {
                return Promise.reject('新密码和确认新密码不一致')
              }
              return Promise.resolve()
            }, trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    showDrawer() {
      this.drawerVisible = true;
    },

    hideDrawer() {
      this.drawerVisible = false;
      this.$refs.form.resetFields();
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        // 未通过表单验证
        return;
      }

      this.submitLoading = true;
      try {
        await this.$http.post('/system/employee/change-password', this.form);
        this.$message.success('密码已修改成功，请重新登录');

        await this.$http.post('/auth/logout');
        clearProfile();
        await this.$router.replace('/login');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    }
  }
}
</script>

<style scoped></style>
