export default {
  key: '/agency/honoraryQualifications',
  title: '荣誉资质',
  permission: '/clientUser/honorAndQualification/getList',
  permissionTit: '荣誉资质',
  config: {
      component: () => import("./views/honoraryQualificationsDetail"),
      actions: [
        {
          text: '新建荣誉资质',
          permission: '/clientUser/honorAndQualification/insert',
        },
        {
          text: '删除',
          permission: '/clientUser/honorAndQualification/delete',
        }
      ],
      rowActions: [
        {
          text: '编辑',
          permission: '/clientUser/honorAndQualification/update',
        }
      ]
  }
}