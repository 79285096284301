import department from "./department";
import employee from "./employee";
import role from "./role";
import customerRules from "./customerRules";
import customerServiceSetting from "./customerServiceSetting";
import sortingRules from "./sortingRules";
import version from "./version";
import optionSet from "./optionSet";
import operationLog from "./operationLog";
import survey from "./survey";
export default [
  department,
  employee,
  role,
  optionSet,
  customerRules,
  customerServiceSetting,
  sortingRules,
  version,
  operationLog,
  survey
];
