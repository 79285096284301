import { intentionLevel, serviceItems, customerStageNew, inCustomerStageNew } from '@/utils/public';
export default {
  title: '机构精准客资',
  type: 'table',
  key: '/newCustomer/accurateCustomer',
  permission: '/agency/accurateCustomer/list',
  config: {
    dataUrl: '/agency/accurateCustomer/list',
    filter: {
      initUrl: '/agency/accurateCustomer/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'select',
          config: {
            options: 'attributionList'
          }
        },
        {
          key: 'staffPersonIdIs',
          label: '客服',
          type: 'select',
          width: '260',
          config: {
            options: "attributionList"
          }
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'mSelect',
          config: {
            options: intentionLevel,
          }
        },
        // {
        //   key: 'customerPhaseIs',
        //   label: '客户阶段',
        //   type: 'select',
        //   config: {
        //     options: inCustomerStageNew,
        //   }
        // },
        {
          key: 'preciseTime',
          label: '转精准时间',
          type: 'dateRange'
        },
      ]
    },
    listFilter: {
      key: 'quickCheck',
      defaultValue: null,
      options: [
        {
          value: null,
          name: '全部'
        },
        {
          value: 'CONTRACT_NEGOTIATION',
          name: '合同谈判',
        },
        {
          value: 'DEAL',
          name: '成交',
        },
        {
          value: 'LOSS',
          name: '流失',
        },
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-preciseTime'
    },
    actions: [
    ],
    columns: [
      {
        key: 'institutionsCustomer.name',
        title: '留服客户姓名',
        width: 150,
      },
      {
        key: 'attributablePerson.name',
        title: '客资归属人',
        width: 150,
      },
      {
        key: 'staffPerson.name',
        title: '客服',
        width: 100,
      },
      {
        key: 'institutions.name',
        title: '所属机构',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.name',
        title: '客户姓名',
        width: 150,
      },
      {
        key: 'institutionsCustomerRelationship.intentionLevel',
        title: '意向等级',
        width: 150,
        type: 'enum',
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '无',
              color: 'grey'
            },
          }
        }
      },
      {
        key: 'signingItem.content',
        title: '预计签单项目',
        width: 150,
      },
      {
        key: 'estimatedSigningAmount',
        title: '预计签单金额(元)',
        width: 150,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'customerPhase',
        title: '客户阶段',
        width: 150,
        type: 'textEnum',
        config: {
          constants: {
            'CONTRACT_NEGOTIATION': '合同谈判',
            'DEAL': '成交',
            'LOSS': '流失'
          }
        }
      },
      {
        key: 'attribution.username',
        title: '归属人',
        width: 150,
      },

      {
        key: 'followUpWithPeople.username',
        title: '跟进人',
        width: 150,
      },

      {
        key: 'preciseTime',
        title: '转精准时间',
        type: 'datetime',
        width: 150,
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '250px'
      }
    ],
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/accurateCustomer/details',
        config: {
          title: '客户详情',
          component: () => import("./views/accurateCustomerDetail")
        }
      },
    ]
  }
}
