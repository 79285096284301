export default {
    key: '/approvalCenter/rebateRulesReview',
    title: '返佣规则审核',
    permission: '/approval/rebatesRules/list',
    config: {
        component: () => import("./views/rebateRulesReviewDetail"),
        actions: [
            {
                key: 'edit',
                text: '通过',
                icon: 'plus',
                type: 'dialog',
                isSelected: true,  // 是否必勾选
                determine: true,  // 是否需要判定条
                permission: '/approval/rebatesRules/pass',
                modalObj: (rowData, tableData) => {
                    return `您共选择 ${rowData.length} 条返佣规则，是否全部通过? `
                },
                config: {
                    title: '审批通过',
                    submitUrl: '/approval/rebatesRules/pass',
                    submitText: '确认',
                    controls: []
                }
            },
            {
                key: 'edit',
                text: '拒绝',
                icon: 'plus',
                type: 'dialog',
                isSelected: true,  // 是否必勾选
                determine: true,  // 是否需要判定条
                permission: '/approval/rebatesRules/refuse',
                modalObj: (rowData, tableData) => {
                    return `您共选择 ${rowData.length} 条返佣规则，是否全部拒绝? `
                },
                config: {
                    title: '审批拒绝',
                    submitUrl: '/approval/rebatesRules/refuse',
                    submitText: '确认',
                    controls: [
                        {
                            key: 'reviewResponse',
                            label: '审批回复',
                            type: 'textarea',
                            required: true,
                            config: {
                                rules: [
                                    { min: 1, max: 50, message: '只能输入1-50个字符' }
                                ]
                            }
                        },
                    ]
                }
            },
        ],
        rowActions: [
            {
                key: 'lock',
                text: '退回',
                type: 'confirm',
                permission: '/approval/rebatesRules/returned',
                disabled: ({ selectedRows }) => {
                    return true
                },
                config: {
                    color: 'primary',
                    submitColor: 'primary',
                    submitUrl: '/approval/rebatesRules/returned',
                    title: '退回后可以重新修改返佣规则重走审核流程，确定要退回吗',
                }
            }
        ]
    }

}

