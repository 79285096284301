import dayjs from "dayjs";
export default {
  key: '/fee/settlement',
  title: '提成结算',
  type: 'table',
  permission: '/commission/list',
  config: {
    scroll: { x: 260 },
    pagination: false,
    rowSpan: true,
    methods: 'postRequest',
    dataUrl: '/commission/list',
    filter: {
      initUrl: "/commission/employeeAll",
      controls: [
        {
          key: 'yearMonth',
          label: '时间',
          type: 'date',
          resetDisabled: true,
          config: {
            picker: 'month',
            initialValue: '上月',
          }
        },
        {
          key: 'employeeId',
          label: '员工',
          type: 'select',
          config: {
            options: 'list'
          }
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出数据',
        type: 'url',
        permission: '/commission/export',
        config: {
          title: '导出数据',
          color: 'primary',
        },
        options: {
          url: '/api/commission/export',
          params: true,
          newPage: true
        }
      },
    ],

    /* 表格排序 */
    sorter: {

    },
    columns: [
      {
        key: 'yearAndMonth',
        title: '月份',
        width: document.documentElement.clientWidth <= 768 ? '60px' : '130px',
        fixed: 'left',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'username',
        title: '姓名',
        width: document.documentElement.clientWidth <= 768 ? '60px' : '130px',
        fixed: 'left',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'departmentName',
        title: '部门',
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'totalAmount',
        title: '业绩',
        type: 'numeric',
        popover: '员工参与的提成返佣之和',
        config: {
          format: 'currency'
        },
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'commissionTotalAmount',
        title: '提成总额',
        type: 'numeric',
        config: {
          format: 'currency'
        },
        width: '130px',
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        key: 'accountStatus',
        title: '账号状态',
        width: '130px',
        type: 'textCustom',
        config: {
          text: (val) => {
            return val == 1 ? '正常' : '作废'
          }
        },
        customCell: (_) => {
          return { rowSpan: _.rowSpan };
        },
      },
      {
        title: '提成明细',
        children: [
          {
            title: '订单号',
            dataIndex: 'detailList_orderNo',
            key: 'detailList_orderNo',
            width: 200,
          },
          {
            title: '签单时间',
            dataIndex: 'detailList_signedTime',
            key: 'detailList_signedTime',
            type: 'datetime',
            width: 200,
          },
          {
            title: '签单机构',
            dataIndex: 'detailList_signedOrg',
            key: 'detailList_signedOrg',
            width: 200,
          },
          {
            title: '应返金额',
            dataIndex: 'detailList_shouldRebateAmount',
            key: 'detailList_shouldRebateAmount',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '返佣时间',
            dataIndex: 'detailList_rebateTime',
            key: 'detailList_rebateTime',
            type: 'datetime',
            width: 200,
          },
          {
            title: '返佣金额',
            dataIndex: 'detailList_rebateAmount',
            key: 'detailList_rebateAmount',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '提成比例',
            dataIndex: 'detailList_commissionRate',
            key: 'detailList_commissionRate',
            width: 200,
            type: 'textCustom',
            config: {
              text: (val) => {
                return val + '%'
              }
            },
          },
          {
            title: '提成金额',
            dataIndex: 'detailList_commissionAmount',
            key: 'detailList_commissionAmount',
            popover: '四舍五入取整',
            type: 'numeric',
            config: {
              format: 'currency'
            },
            width: 200,
          },
          {
            title: '提成角色',
            dataIndex: 'detailList_roleType',
            key: 'detailList_roleType',
            width: 200,
            type: 'textEnum',
            config: {
              constants: {
                1: '业务归属人',
                2: '客资归属人',
                3: '客服',
                4: '运营',
                // 5: '主播',
                6: '管理层'
              }
            }
          }
        ],
      },
    ],

    /* 表格行操作 */
    rowActions: []
  }
};
