import { citysMenus } from '@/city.js';
import { country, companyEntity } from '@/utils/public';
export default {
  key: '/agency/institutions',
  title: '机构列表',
  type: 'table',
  permission: '/agency/institutions/list',
  config: {
    checkbox: true,
    dataUrl: '/agency/institutions/list',
    filter: {
      initUrl: '/agency/institutions/list/get',
      controls: [
        {
          key: 'nameOrContactOrPhoneNumberOrCompanySubjectContains',
          label: '关键字',
          type: 'text'
        },
        {
          key: 'provinceCityDistrictContains',
          label: '城市',
          type: 'cascader',
          config: {
            options: citysMenus
          }
        },
        {
          key: 'lockedIs',
          label: '状态',
          type: 'select',
          config: {
            options: [
              {
                text: '启用',
                value: false
              },
              {
                text: '停用',
                value: true
              }
            ]
          }
        },
        {
          key: 'signingTime',
          label: '签约时间',
          type: 'dateRange'
        },
        {
          key: 'businessTypeContainsIn',
          label: '业务类型',
          type: 'mSelect',
          width: '260',
          config: {
            options: companyEntity
          }
        },
        {
          key: 'countryOfApplicationContainsIn',
          label: '申请国家',
          type: 'mSelect',
          width: '260',
          config: {
            options: country
          }
        },
        {
          key: 'activateIdIs',
          label: '业务归属人',
          type: 'select',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'shopIs',
          label: '是否开通门店',
          type: 'select',
          config: {
            options: [
              {
                text: '全部',
                value: null
              },
              {
                text: '是',
                value: true
              },
              {
                text: '否',
                value: false
              }
            ]
          }
        },
      ]
    },
    columns: [
      {
        key: 'name',
        title: '机构名称',
        width: '150px',
      },
      {
        key: 'systemScore',
        title: '机构评分',
        width: '90px',
        sorter: true,
      },
      {
        key: 'contact',
        title: '联系人',
        width: '100px',
      },
      {
        key: 'phoneNumber',
        title: '联系方式',
        width: '120px',
        config: {
          desensitization: 'phoneNumber'
        }
      },
      {
        key: 'provinceCityDistrict',
        type: 'splitCity',
        title: '城市',
        width: '80px',
      },
      {
        key: 'businessType',
        title: '业务类型',
        width: '250px',
        ellipsis: true
      },
      {
        key: 'companySubject',
        title: '公司主体',
        width: '250px',
        ellipsis: true
      },
      // {
      //   key: 'crm',
      //   title: '是否开通CRM',
      //   type: 'enum',
      //   width: '130px',
      //   align: 'center',
      //   config: {
      //     constants: {
      //       true: {
      //         text: '是',
      //         color: 'orange'
      //       },
      //       false: {
      //         text: '否',
      //         color: 'green'
      //       }
      //     }
      //   }
      // },
      {
        key: 'shop',
        title: '是否开通门店',
        type: 'enum',
        width: '130px',
        align: 'center',
        config: {
          constants: {
            true: {
              text: '是',
              color: 'orange'
            },
            false: {
              text: '否',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'locked',
        title: '状态',
        type: 'enum',
        width: '65px',
        align: 'center',
        config: {
          constants: {
            false: {
              text: '启用',
              color: 'orange'
            },
            true: {
              text: '停用',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'activate.name',
        title: '业务归属人',
        width: '100px',
      },
      {
        key: 'inviteNum',
        title: '邀请下级(家)',
        width: '150px',
        type: 'modal',
        disabled: (row)=>{
          if(row.inviteNum > 0) {
            return false
          } else {
            return true
          }
        },
        config: {
          format: 'currency',
          text: (row) => {
            return row.inviteNum
          },
          title: '',
          table: true,
          needUrl: false,
          columns: [
            {
              title: '机构名',
              dataIndex: 'institution',
            },
            {
              title: '入驻时间',
              dataIndex: 'time',
              key: 'time',
              type: 'datetime'
            },
            {
              title: '邀请账号',
              dataIndex: 'inviter',
            }
          ],
        }
      },
      {
        key: 'signingTime',
        title: '签约时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'remarks',
        title: '备注',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '200px'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      // sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    actions: [
      {
        key: 'detail',
        text: '添加',
        icon: 'PlusOutlined',
        type: 'blank',
        permission: '/agency/institutions/add',
        config: {
          title: '添加机构',
          color: 'primary',
          component: () => import("./views/addInstitutionsDetail")
        }
      },
      {
        key: 'edit',
        text: '修改业务归属人',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/agency/institutions/setAttributablePerson',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 家机构，请选择业务归属人`
        },
        config: {
          title: '修改业务归属人',
          initUrl: '/agency/institutions/setAttributablePerson/get',
          submitUrl: '/agency/institutions/setAttributablePerson',
          submitText: '确认',
          controls: [
            {
              key: 'attributablePersonId',
              label: '业务归属人',
              type: 'souSelect',
              required: true,
              config: {
                options: "employeesList"
              }
            },
          ]
        }
      },
      {
        key: 'download',
        text: '导出机构列表',
        icon: 'plus',
        type: 'dialog',
        determine: true,  // 是否需要判定条
        permission: '/agency/institutions/export',
        modalObj: (rowData, tableData) => {
          return `即将导出当前列表的全部机构为excel，确定导出吗`
        },
        options: {
          url: '/api/agency/institutions/export',
          params: true,
          newPage: true
        },
        config: {
          title: '导出机构列表',
          submitText: '确认',
          controls: []
        }
      },
    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '编辑',
        type: 'blank',
        permission: '/agency/institutions/edit',
        config: {
          title: '编辑机构',
          color: 'primary',
          component: () => import("./views/editInstitutionsDetail")
        }
      },
      {
        key: 'edit',
        text: '评分',
        type: 'form',
        permission: '/agency/institutions/rating',
        config: {
          color: 'primary',
          title: '机构评分',
          initUrl: "/agency/institutions/rating/get",
          submitUrl: "/agency/institutions/rating",
          submitText: '确认',
          controls: [
            {
              key: 'name',
              label: '机构名',
              type: 'text',
              disabled: true,
            },
            {
              key: 'systemScore',
              label: '当前评分',
              type: 'text',
              disabled: true,
            },
            {
              key: 'reason',
              label: '评分理由',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'fraction',
              label: '本次加分/扣分',
              type: 'numeric',
              required: true,
              tips: "可输入负数，负数即扣分",
              config: {
                max: (form) => {
                  return 100 - form.systemScore
                },
                min: (form) => {
                  if (form.systemScore == 0) {
                    return 0
                  }
                  return -form.systemScore
                }
              }
            },
            {},
            {
              key: 'name',
              label: '评分记录',
              type: 'table',
              scrollHeight: 240,
              config: {
                options: "scoreRecordList",
                columns: [
                  {
                    dataIndex: 'reason',
                    key: 'reason',
                    title: '评分理由',
                    width: '150px',
                  },
                  {
                    dataIndex: 'fraction',
                    key: 'fraction',
                    title: '评分',
                    width: '150px',
                  },
                  {
                    dataIndex: 'operator.username',
                    key: 'operator.username',
                    title: '操作人',
                    width: '150px',
                  },
                  {
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    title: '操作时间',
                    type: 'datetime',
                    width: '150px',
                  },
                ]
              }
            },

          ]
        }
      },
      {
        key: 'lock',
        text: '停用',
        type: 'confirm',
        permission: '/agency/institutions/lock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === false;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/agency/institutions/lock'
        }
      },
      {
        key: 'unlock',
        text: '恢复',
        type: 'confirm',
        permission: '/agency/institutions/unlock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/agency/institutions/unlock'
        }
      },

      // {
      //   key: 'audit',
      //   text: '审核',
      //   type: 'blank',
      //   disabled({ selectedRows }) {
      //     return selectedRows[0].auditState !== 'WAIT_AUDIT';
      //   },
      //   permission: '/agency/institutions/audit',
      //   config: {
      //     component: () => import("./views/institutionlibAudit")
      //   }
      // },

    ]
  }
};
