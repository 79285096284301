export default {
  key: '/agency/admissionCases',
  title: '录取案例',
  permission: '/clientUser/caseManagement/getList',
  permissionTit: '录取案例',
  config: {
      component: () => import("./views/admissionCasesDetail"),
      actions: [
        {
          text: '新建录取案例',
          permission: '/clientUser/caseManagement/insert',
        },
        {
          text: '删除',
          permission: '/clientUser/caseManagement/delete',
        }
      ],
      rowActions: [
        {
          text: '编辑',
          permission: '/clientUser/caseManagement/update',
        }
      ]
  }
} 