
export default {
  key: '/registeredUsers/userList',
  title: '用户列表',
  type: 'table',
  permission: '/clientUser/admin/getClientUserList',
  config: {
    dataUrl: '/clientUser/admin/getClientUserList',
    filter: {
      // initUrl: '/system/employee/list/get',
      controls: [
        {
          key: 'nicknameOrUid',
          label: '昵称/UID',
          type: 'text'
        },
        {
          key: 'phoneNumber',
          label: '手机号',
          type: 'text'
        },
        {
          key: 'time',
          label: '注册时间',
          type: 'dateRange'
        }
      ]
    },

    actions: [
    ],

    columns: [

      {
        key: 'uid',
        width: '100px',
        title: 'UID'
      },
      {
        key: 'nickname',
        width: '100px',
        title: '昵称'
      },
      {
        key: 'phoneNumber',
        width: '100px',
        title: '手机号'
      },
      {
        key: 'registrationPlatform',
        width: '100px',
        title: '注册平台'
      },
      {
        key: 'time',
        title: '注册时间',
        type: 'datetime',
        width: '200px',
      },
      // {
      //   key: 'name',
      //   title: '操作',
      //   fixed: 'right',
      //   type: 'actions',
      //   width: document.documentElement.clientWidth <= 768 ? '84px' : '300px'
      // }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      
    ]
  }
};
