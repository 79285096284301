
import { intentionLevel } from '@/utils/public';
export default {
  key: '/allocationCustomer/followup',
  title: '机构跟进记录',
  type: 'table',
  permission: '/agency/institutionsFollowUp/list',
  config: {
    dataUrl: '/agency/institutionsFollowUp/list',
    filter: {
      initUrl: '/agency/institutionsFollowUp/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'institutionsIdIs',
          label: '跟进机构',
          type: 'souSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'followUpTime',
          label: '跟进时间',
          type: 'dateRange'
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'select',
          config: {
            options: intentionLevel
          }
        },
        {}
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['followUpTime'],
      /* 默认排序 */
      defaultSort: '-followUpTime'
    },
    actions: [

    ],

    columns: [
      {
        key: 'institutionsCustomerRelationship.name',
        width: '150px',
        title: '客户姓名'
      },
      {
        key: 'attributablePerson.name',
        width: '120px',
        title: '客资归属人'
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '跟进机构'
      },
      {
        key: 'institutionsCustomerRelationship.intentionLevel',
        title: '意向等级',
        type: 'enum',
        width: '80px',
        align: 'center',
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            }
          }
        }
      },
      {
        key: 'followUpPeople.name',
        width: '150px',
        title: '跟进人'
      },
      {
        key: 'followUpTime',
        title: '跟进时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'followUpContent',
        title: '跟进内容',
        width: '150px',
        ellipsis: true
      },
      {
        key: 'nextFollowUpTime',
        title: '下次跟进时间',
        type: 'datetime',
        width: '110px',
      },
      {
        key: 'createdDate',
        title: '记录创建时间',
        type: 'datetime',
        width: '110px',
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '50px' : '80px'
      }

    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/institutionsFollowUp/details',
        config: {
          title: '客户详情',
          component: () => import("./views/followupCusDetail")
        }
      },
    ]
  }
};
