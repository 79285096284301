
import followup from "./followup";
import accurateCustomer from "./accurateCustomer";
import allocationCustomer from "./allocationCustomer";
import highSeas from "./highSeas";

export default [
  followup,
  accurateCustomer,
  allocationCustomer,
  highSeas
];
