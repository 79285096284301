import pages from "./pages";

export default {
  pages,
  menus: [
    {
      key: 'dataReport',
      text: '数据报表',
      icon: 'LineChartOutlined',
      defaultOpen: false,
      pages: [
        '/dataReport/dataGlance',
        '/dataReport/signData',
        '/dataReport/institutionalData',
        '/dataReport/channelEmployeeData',
        '/dataReport/marketEmployeeData',
        '/dataReport/customerServiceData',
        '/dataReport/customerSourceData'
      ]
    },
    {
      key: 'newCustomer',
      text: '客户管理',
      icon: 'UserOutlined',
      defaultOpen: false,
      pages: [
        '/newCustomer/institutionsCustomer',
        '/newCustomer/allocationCustomer',
        '/newCustomer/accurateCustomer',
        '/newCustomer/followRecord',
        '/newCustomer/highSeas'
      ]
    },
    // {
    //   key: 'customer',
    //   text: '留服客户',
    //   icon: 'UserOutlined',
    //   defaultOpen: false,
    //   pages: [
    //     '/customer/toBeAllocated',
    //     '/customer/institutionsCustomer',
    //     '/customer/followup',
    //     '/customer/visitCustomer',
    //   ]
    // },
    // {
    //   key: 'allocationCustomer',
    //   text: '机构客户',
    //   icon: 'TeamOutlined',
    //   defaultOpen: false,
    //   pages: [
    //     '/allocationCustomer/allocationCustomer',
    //     '/allocationCustomer/followup',
    //     '/allocationCustomer/highSeas',
    //   ]
    // },
    {
      key: 'order',
      text: '订单管理',
      icon: "DeploymentUnitOutlined",
      defaultOpen: false,
      pages: [
        '/order/institutionsOrder',
        '/order/cancelorder',
        '/order/transactions',
        '/order/rebate',
      ]
    },
    {
      key: 'agency',
      text: '机构管理',
      icon: 'GoldOutlined',
      defaultOpen: false,
      pages: [
        '/agency/institutions',
        '/agency/applyInstitutions',
        '/agency/institutionsEmployee',
        '/agency/storeManage',
        // '/agency/institutionsStore',
        // '/agency/diyService',
        '/agency/comment'
      ]
    },
    {
      key: 'registeredUsers',
      text: '注册用户',
      icon: 'UsergroupAddOutlined',
      defaultOpen: false,
      pages: [
        '/registeredUsers/userIntention',
        // '/registeredUsers/userList',
        '/registeredUsers/platformData',
        '/registeredUsers/storeData',
      ]
    },
    {
      key: 'approvalCenter',
      text: '审批中心',
      icon: "AccountBookOutlined",
      defaultOpen: false,
      pages: [
        '/approvalCenter/rebateRulesReview',
        '/approvalCenter/rebateCollectionReview',
        '/approvalCenter/institutionalCooperationReview',
      ]
    },
    {
      key: 'fee',
      text: '业绩提成',
      icon: "TransactionOutlined",
      defaultOpen: false,
      pages: [
        '/fee/settlement',
      ]
    },
    {
      key: 'manual',
      text: '消息管理',
      icon: 'MessageOutlined',
      defaultOpen: false,
      pages: [
        '/message/manual',
        '/message/system',
        '/message/message'
      ]
    },
    {
      key: 'content',
      text: '内容管理',
      icon: 'BookOutlined',
      defaultOpen: false,
      pages: [
        '/content/strategyInfo',
        '/content/banner',
      ]
    },
    {
      key: 'college',
      text: '院校管理',
      icon: 'BankOutlined',
      defaultOpen: false,
      pages: [
        '/college/college',
        '/college/major',
        '/college/ranking'
      ]
    },
    {
      key: 'system',
      text: '系统设置',
      icon: 'SettingOutlined',
      defaultOpen: false,
      pages: [
        '/system/employee',
        '/system/department',
        '/system/role',
        '/system/optionSet',
        '/system/customerRules',
        '/system/customerServiceSetting',
        '/system/sortingRules',
        '/system/operationLog',
        '/system/survey',
        '/system/version'
      ]
    }
  ]
}
