export default {
    key: '/newCustomer/followRecord',
    title: '跟进记录',
    permissionTit: '机构跟进记录-查看列表',
    permission: '/agency/institutionsFollowUp/list',
    config: {
        component: () => import("./tabviews/followRecordDetail"),
        actions: [
            {
                text: '机构跟进记录-详情',
                permission: '/agency/institutionsFollowUp/details',
            },
            {
                text: '留服跟进记录-查看列表',
                permission: '/agency/followUpRecords/list',
            },
            {
                text: '留服跟进记录-详情',
                permission: '/agency/followUpRecords/details',
            },
        ]
    }

}

