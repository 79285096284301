export default {
  key: '/agency/strategyInfo',
  title: '攻略资讯',
  permission: '/clientUser/userEvaluation/getList',
  permissionTit: '攻略资讯',
  config: {
      component: () => import("./views/strategyInfoDetail"),
      actions: [
        {
          text: '攻略资讯',
          permission: '/clientUser/userEvaluation/insert',
        },
        {
          text: '删除',
          permission: '/clientUser/userEvaluation/delete',
        }
      ],
      rowActions: [
        {
          text: '编辑',
          permission: '/clientUser/userEvaluation/update',
        }
      ]
  }
} 