export default {
  key: '/dataReport/customerSourceData',
  title: '客户来源数据',
  type: 'table',
  permission: '/report/customer/source/list',
  config: {
    dataUrl: '/report/customer/source/list',
    summary: true,
    sticky: true,
    pagination: false,
    scroll: { x: 1200 },
    filter: {
      controls: [
        {
          key: 'time',
          label: '时间',
          type: 'dateRange',
          resetDisabled: true,
          config: {
            initialValue: '当月',
          }
        },
      ]
    },
    columns: [
      {
        key: 'customerSource',
        title: '客户来源',
        width: '150px',
      },
      {
        key: 'numberOfNewCustomer',
        title: '新增客资',
        width: '150px',
        popover: '时间维度：客资创建时间',
        summary: true,
      },
      {
        key: 'numberOfAllocate',
        title: '分配客资',
        width: '150px',
        summary: true,
        popover: '时间维度：客资创建时间',
      },
      {
        key: 'numberOfAllocateOrg',
        title: '分配机构(家)',
        popover: '时间维度：客资创建时间',
        width: '150px',
        summary: true,
      },
      {
        key: 'orgSignedNum',
        title: '机构签单数(单)',
        width: '150px',
        summary: true,
      },
      {
        key: 'orgSignedOrderAmount',
        title: '机构签单额(元)',
        width: '150px',
        summary: true,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'orgSignedPaidAmount',
        title: '已返金额(元)',
        width: '150px',
        summary: true,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['id'],
      /* 默认排序 */
      defaultSort: '-id'
    },
    actions: [
      {
        key: 'chapter',
        text: '导出为Excel',
        type: 'url',
        permission: '/report/customer/source/export',
        config: {
          title: '导出为Excel',
          color: 'primary',
        },
        options: {
          url: '/api/report/customer/source/export',
          params: true,
          newPage: true
        }
      },
    ],
    /* 表格行操作 */
    rowActions: [
    ]
  }
};
