
export default {
  key: '/message/message',
  title: '消息模板管理',
  permission: '/message/template/list',
  config: {
    component: () => import("./views/messageDetail"),
    actions: [
      {
        text: '新建',
        permission: '/message/template/add',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/message/template/edit',
      },
      {
        text: '删除',
        permission: '/message/template/delete',
      },
    ]
  }

}
