export default {
  key: '/newCustomer/visitCustomer',
  title: '客户回访',
  permission: '/retention/customerReturnVisits/list',
  config: {
    component: () => import("./views/visitCustomerDetail"),
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/retention/customerReturnVisits/details',
        config: {
          title: '客户详情',
          component: () => import("./views/vistitDetail/visitDetail")
        }
      },
      {
        key: 'detail',
        text: '执行回访',
        type: 'blank',
        permission: '/retention/customerReturnVisits/returnVisit',
        config: {
          title: '执行回访',
          component: () => import("./views/vistitDetail/toVisit")
        }
      },
    ],
    /* 表格操作 */
    actions: [
      {
        key: 'edit',
        text: '取消回访',
        icon: 'plus',
        type: 'form',
        isSelected: true,  // 是否必勾选
        permission: '/retention/customerReturnVisits/cancel',
        config: {
          title: '取消回访',
          submitUrl: '/retention/customerReturnVisits/cancel',
          submitText: '确认',
          info: (rows) => {
            return `您共选择 ${rows.length} 条回访计划,是否全部取消`
          },
          controls: [
            {
              key: 'outcome',
              label: '取消原因',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 200, message: '只能输入1-200个字符' }
                ]
              }
            },
          ]
        }
      },
    ],
  },

}

