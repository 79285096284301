export default {
    key: '/dataReport/channelEmployeeData',
    title: '渠道员工数据',
    type: 'table',
    permission: '/report/channel/list',
    config: {
        pagination: false,
        summary: true,
        sticky: true,
        scroll: { x: 1200 },
        dataUrl: '/report/channel/list',
        filter: {
            initUrl: '/report/channel/list/get',
            controls: [
                {
                    key: 'time',
                    label: '时间',
                    type: 'dateRange',
                    resetDisabled: true,
                    config: {
                        initialValue: '当月',
                    }
                },
                {
                    key: 'departmentIdIs',
                    label: '部门',
                    type: 'select',
                    config: {
                        options: 'departmentList'
                    }
                },
            ]
        },
        columns: [
            {
                key: 'username',
                title: '姓名',
                width: '150px',
            },
            {
                key: 'departmentName',
                title: '所属部门',
                width: '150px',
            },
            {
                key: 'signingAgency',
                title: '签约机构(家)',
                popover: '根据客资分配时间统计',
                width: '150px',
                summary: true
            },
            // {
            //     key: 'openingMechanism',
            //     title: '开通CRM机构(家)',
            //     popover: '根据机构CRM的开通时间统计',
            //     width: '150px',
            //     summary: true,
            // },
            {
                key: 'agencySignature',
                title: '机构签单(单)',
                popover: '根据机构提供的签单时间统计',
                width: '150px',
                summary: true,
            },
            {
                key: 'signedAmount',
                title: '机构签单额(元)',
                width: '250px',
                summary: true,
                type: 'numeric',
                config: {
                    format: 'currency'
                }
            },
            {
              key: 'returnedAmount',
              title: '已返金额',
              popover: '根据收到返佣时间统计',
              width: '150px',
              summary: true,
              type: 'numeric',
              config: {
                format: 'currency'
              }
            },

        ],

        /* 表格排序 */
        sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
        },
        actions: [
            {
                key: 'chapter',
                text: '导出为Excel',
                type: 'url',
                permission: '/report/channel/export',
                config: {
                    title: '导出为Excel',
                    color: 'primary',
                },
                options: {
                    url: '/api/report/channel/export',
                    params: true,
                    newPage: true
                }
            },
        ],
        /* 表格行操作 */
        rowActions: [


        ]
    }
};
