import { receptionCity } from '@/utils/public';
export default {
  key: '/agency/institutionsStore',
  title: '机构门店',
  type: 'table',
  permission: '/agency/institutionsEmployee/list',
  config: {
    /* 表格是否可多选 */
    dataUrl: '/agency/institutionsEmployee/list',
    filter: {
      initUrl: '/agency/institutionsEmployee/list/get',
      controls: [
        {
          key: 'institutionsIdIs',
          label: '机构名',
          type: 'souSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'city',
          label: '城市',
          type: 'souSelect',
          config: {
            options: receptionCity
          }
        },
        {}
      ]
    },
    columns: [
      {
        key: 'name',
        title: '机构名',
        width: '100px',
      },
      {
        key: 'name',
        title: '城市',
        width: '100px',
      },
      {
        key: 'name',
        title: '评分',
        width: '100px',
      },
      {
        key: 'username',
        title: '服务形目',
        width: '150px',
        type: 'link',
        config: {
          path: "/agency/serviceItems",
        }
      },
      {
        key: 'username',
        title: '服务团队',
        width: '150px',
        type: 'link',
        config: {
          path: "/agency/serviceTeam",
        }
      },
      {
        key: 'username',
        title: '荣誉资质',
        width: '150px',
        type: 'link',
        config: {
          path: "/agency/honoraryQualifications",
        }
      },
      {
        key: 'username',
        title: '录取案例',
        width: '150px',
        type: 'link',
        config: {
          path: "/agency/admissionCases",
        }
      },
      {
        key: 'username',
        title: '用户评价',
        width: '150px',
        type: 'link',
        config: {
          path: "/agency/userEvaluate",
        }
      },
      {
        key: 'name',
        title: '门店状态',
        width: '100px',
      },

      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '100px' : '180px'
      }

    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    actions: [
      {
        key: 'edit',
        text: '新建机构门店',
        type: 'form',
        permission: '/agency/institutionsEmployee/edit',
        config: {
          color: 'primary',
          title: '新建机构门店',
          initUrl: "/agency/institutionsEmployee/edit/get",
          submitUrl: "/agency/institutionsEmployee/edit",
          submitText: '保存',
          controls: [
            {
              key: 'institutionsId',
              label: '机构名',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'institutionsId',
              label: '办理国家/地区',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'institutionsId',
              label: '所在城市',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'asd',
              label: '评分',
              type: 'rate',
              allowHalf: false,
              required: true,
            },
            {
              key: 'username',
              label: '标签1',
              type: 'text',
            },
            {
              key: 'username',
              label: '标签2',
              type: 'text',
            },
            {
              key: 'username',
              label: '标签3',
              type: 'text',
            },
            {
              key: 'username',
              label: '机构简介',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 90, message: '请尽量精简内容，输入90字以内' }
                ]
              }
            },
            {
              key: 'paymentVoucher',
              label: 'logo',
              required: true,
              tips: "建议上传正方形或圆形；大小不超过500kb的图片",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'paymentVoucher',
              label: '环境图片',
              required: true,
              tips: "建议上传比例为 604:423 ；大小不超过1mb的图片；不超过9张",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'home',
              label: '是否热门',
              type: 'radio',
              required: true,
              config: {
                initialValue: false,
                options: [
                  {
                    text: '否',
                    value: false
                  },
                  {
                    text: '是',
                    value: true
                  },
                ]
              }
            },
          ]
        }
      },
    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/agency/institutionsEmployee/edit',
        config: {
          color: 'primary',
          title: '编辑',
          initUrl: "/agency/institutionsEmployee/edit/get",
          submitUrl: "/agency/institutionsEmployee/edit",
          submitText: '保存',
          controls: [
            {
              key: 'institutionsId',
              label: '机构名',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'institutionsId',
              label: '办理国家/地区',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'institutionsId',
              label: '所在城市',
              type: 'select',
              required: true,
              disabled: true,
              config: {
                options: 'institutionsList'
              }
            },
            {
              key: 'asd',
              label: '评分',
              type: 'rate',
              allowHalf: false,
              required: true,
            },
            {
              key: 'username',
              label: '标签1',
              type: 'text',
            },
            {
              key: 'username',
              label: '标签2',
              type: 'text',
            },
            {
              key: 'username',
              label: '标签3',
              type: 'text',
            },
            {
              key: 'username',
              label: '机构简介',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 90, message: '请尽量精简内容，输入90字以内' }
                ]
              }
            },
            {
              key: 'paymentVoucher',
              label: 'logo',
              required: true,
              tips: "建议上传正方形或圆形；大小不超过500kb的图片",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'paymentVoucher',
              label: '环境图片',
              required: true,
              tips: "建议上传比例为 604:423 ；大小不超过1mb的图片；不超过9张",
              type: 'file',
              config: {
                uploadUrl: '/api/order/institutionsOrder/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg']
              }
            },
            {
              key: 'home',
              label: '是否热门',
              type: 'radio',
              required: true,
              config: {
                initialValue: false,
                options: [
                  {
                    text: '否',
                    value: false
                  },
                  {
                    text: '是',
                    value: true
                  },
                ]
              }
            },
          ]
        }
      },
      {
        key: 'reset-password',
        text: '复制门店',
        type: 'confirm',
        permission: '/agency/institutionsEmployee/reset-password',
        disabled: true,
        config: {
          color: 'danger',
          title: '确定对该条记录进行重置密码操作吗？',
          submitUrl: '/agency/institutionsEmployee/reset-password',
          submitText: '确认',
          submitColor: 'danger',
          submitSuccessMessage: '重置密码成功，默认密码：000000',
          submitLoadingMessage: '正在重置密码 ...'
        }
      },

    ]
  }
};
