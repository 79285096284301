export default {
    key: '/approvalCenter/rebateCollectionReview',
    title: '返佣收支审核',
    permission: '/approval/rebateAudit/list',
    config: {
        component: () => import("./views/rebateCollectionReviewDetail"),
        actions: [
            // {
            //     key: 'edit',
            //     text: '通过',
            //     icon: 'plus',
            //     type: 'dialog',
            //     isSelected: true,  // 是否必勾选
            //     determine: true,  // 是否需要判定条
            //     permission: '/approval/rebateAudit/pass',
            //     modalObj: (rowData, tableData) => {
            //         return `您共选择 ${rowData.length} 条返佣收支记录，是否全部通过? `
            //     },
            //     config: {
            //         title: '审批通过',
            //         submitUrl: '/approval/rebateAudit/pass',
            //         submitText: '确认',
            //         controls: []
            //     }
            // },
            {
                key: 'edit',
                text: '拒绝',
                icon: 'plus',
                type: 'dialog',
                isSelected: true,  // 是否必勾选
                determine: true,  // 是否需要判定条
                permission: '/approval/rebateAudit/refuse',
                modalObj: (rowData, tableData) => {
                    return `您共选择 ${rowData.length} 条返佣收支记录，是否全部拒绝?`
                },
                config: {
                    title: '审批拒绝',
                    submitUrl: '/approval/rebateAudit/refuse',
                    submitText: '确认',
                    controls: [
                        {
                            key: 'reviewResponse',
                            label: '审批回复',
                            type: 'textarea',
                            required: true,
                            config: {
                                rules: [
                                    { min: 1, max: 50, message: '只能输入1-50个字符' }
                                ]
                            }
                        },
                    ]
                }
            },
        ],
        rowActions: [
            {
                text: '审批通过',
                permission: '/approval/rebateAudit/pass',
            },
            {
                text: '提成编辑',
                permission: '/approval/rebateAudit/save',
            }
        ],
    }

}

