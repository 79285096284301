
import agency from "./agency";
import system from "./system";
import customer from "./customer";
import allocationCustomer from "./allocationCustomer"
import order from "./order";
import approvalCenter from "./approvalCenter";
import dataReport from "./dataReport";
import newCustomer from "./newCustomer";
import registeredUsers from "./registeredUsers";
import content from "./content";
import college from "./college";
import message from "./message";
import fee from "./fee";
export default [
  ...dataReport,
  ...newCustomer,
  ...agency,
  ...order,
  ...system,
  ...customer,
  ...allocationCustomer,
  ...approvalCenter,
  ...registeredUsers,
  ...content,
  ...college,
  ...message,
  ...fee,
  {
    key: '/',
    title: '首页',
    type: 'blank',
    config: {
      component: () => import("./Home")
    }
  }
].reduce((prev, page) => {
  prev[page.key] = page;
  return prev;
}, {});
