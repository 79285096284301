import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../pages/MainLayout';
import TablePage from '@/components/TablePage';
import NotFound from '@/pages/NotFound';
import Login from '@/pages/Login';
import config from '@/config';
import Nprogress from 'nprogress';
import message from '@/pages/msg/message';
import msgDetail from '@/pages/msg/msgDetail';
import { getProfile } from "@/utils/session";
import store from '@/store'
const username = getProfile().then(res => {
    return res.username
})
const home = store.state.isHome
const routes = [
    {
        path: '/login',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    // {
    //     path: '/',
    //     redirect: home ? '/dataReport/dataGlance' : '/home',
    // },
    {
        path: '/',
        component: MainLayout,
        children: [
            ...Object
                .keys(config.pages)
                .map(key => {
                    const page = config.pages[key];
                    return {
                        path: key,
                        meta: {
                            title: page.title
                        },
                        component: page.type === 'table' ? {
                            components: { TablePage },
                            render() {
                                return <table-page config={page.config} />;
                            }
                        } : page.config.component
                    };
                }),
            {
                path: '*',
                component: NotFound
            },
            {
                path: '/message',
                name: 'message',
                component: message,
                meta: {
                    title: '消息'
                }
            },
            {
                path: '/msgdetail',
                name: 'msgDetail',
                component: msgDetail,
                meta: {
                    title: '消息'
                }
            },

        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
import waterMark from "../utils/waterMark";
router.beforeEach((to, from, next) => {
    Nprogress.start();
    document.title = to.meta.title + ' · 留学SAAS管理系统';
    username.then((res) => {
        waterMark.set(res)
    })

    next();
});
router.afterEach(() => {
    Nprogress.done();
});
export default router;
