export default {
  key: '/agency/comment',
  title: '用户评价',
  permission: '/clientUser/myFeedBack/getCommentList',
  config: {
    component: () => import("./commentViews/comment"),
    actions: [
      {
        text: '机构评价-列表',
        permission: '/clientUser/myFeedBack/getCommentList',
      },
      {
        text: '文章评论-列表',
        permission: '/article/comment/list',
      },
    ],
    rowActions: [
      {
        text: '机构评价-删除',
        permission: '/clientUser/myFeedBack/delete',
      },
      {
        text: '文章评论-删除',
        permission: '/article/comment/delete',
      },
    ]
  }
}