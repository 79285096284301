import { carouselPosition, appRoute } from '@/utils/public';
export default {
  key: '/content/banner',
  title: 'banner管理',
  type: 'table',
  permission: '/banner/list',
  config: {
    dataUrl: '/banner/list',
    filter: {
      controls: [
        {
          key: 'location',
          label: '轮播位置',
          type: 'select',
          config: {
            options: carouselPosition
          }
        },
      ]
    },
    actions: [
      {
        key: 'add',
        text: '新建轮播图',
        icon: 'plus',
        type: 'form',
        permission: '/banner/save',
        config: {
          color: 'primary',
          title: '新建轮播图',
          submitUrl: '/banner/save',
          submitText: '确认添加',
          controls: [
            {
              key: 'picture',
              label: '封面',
              required: true,
              tips: `建议图片尺寸：APP-banenr：351*140 小程序-banenr：351*140 网站移动-banenr：351*140 网站PC-banenr：1920*710`,
              type: 'file',
              config: {
                uploadUrl: '/api/banner/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg'],
                maxSize: 1024 * 1000 * 5
              }
            },
            {},
            {
              key: 'location',
              label: '轮播位置',
              type: 'select',
              required: true,
              config: {
                options: carouselPosition
              }
            },
            {
              key: 'name',
              label: '名称',
              type: 'text',
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'url',
              label: '跳转链接',
              type: 'text',
              tips: `输入跳转网址则banner可点击跳转`,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return true;
                  } else {
                    return false;
                  }
                },
              }
            },
            {
              key: 'url',
              label: '跳转位置',
              type: 'select',
              tips: `选择跳转位置并输入跳转参数可点击跳转`,
              config: {
                options: appRoute,
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'param',
              label: '参数',
              type: 'text',
              config: {
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'display',
              label: '是否展示',
              type: 'radio',
              required: true,
              config: {
                initialValue: 1,
                options: [
                  {
                    text: '是',
                    value: 1
                  },
                  {
                    text: '否',
                    value: 0
                  },
                ]
              }
            },
            {
              key: 'sort',
              width: '130px',
              label: '排序',
              type: 'numeric',
              required: true,
              config: {
                format: 'sort'
              }
            },
            {},
            {},
            {}
          ]
        }
      }
    ],

    columns: [
      {
        key: 'name',
        width: '100px',
        title: '名称'
      },
      {
        key: 'picture',
        title: '图片',
        width: '100px',
        type: 'image'
      },
      {
        key: 'location',
        title: '轮播位置',
        width: '100px',
      },
      {
        key: 'display',
        width: '100px',
        title: '是否展示',
        type: 'textCustom',
        config: {
          text: (val) => {
            return val == 1 ? '是' : '否'
          }
        }
      },
      {
        key: 'url',
        width: '200px',
        title: '跳转链接',
        type: 'textCustomCol',
        config: {
          text: (col) =>{
            if(col.location == 'APP' && col.url) {
              let val = appRoute.find((item,index,arr) =>{
                return item.id == col.url
              })
              return val.name
            } else {
              return col.url
            }
          }
        }
      },
      {
        key: 'sort',
        width: '100px',
        title: '排序',
        ellipsis: true
      },
      {
        key: 'asd',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: '84px'
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/banner/update',
        config: {
          color: 'primary',
          title: '编辑轮播图',
          initUrl: '/banner/update/get',
          submitUrl: '/banner/update',
          submitText: '确认添加',
          controls: [
            {
              key: 'picture',
              label: '封面',
              required: true,
              tips: `建议图片尺寸：APP-banenr：351*140 小程序-banenr：351*140 网站移动-banenr：351*140 网站PC-banenr：1920*710`,
              type: 'file',
              config: {
                uploadUrl: '/api/banner/addUpload',
                format: "image",
                accept: ["jpeg", "png", 'jpg'],
                maxSize: 1024 * 1000 * 5
              }
            },
            {},
            {
              key: 'location',
              label: '轮播位置',
              type: 'select',
              disabled: true,
              required: true,
              config: {
                options: carouselPosition
              }
            },
            {
              key: 'name',
              label: '名称',
              type: 'text',
              config: {
                rules: [
                  { min: 1, max: 20, message: '只能输入1-20个字符' }
                ]
              }
            },
            {
              key: 'url',
              label: '跳转链接',
              type: 'text',
              tips: `输入跳转网址则banner可点击跳转`,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return true;
                  } else {
                    return false;
                  }
                },
              }
            },
            {
              key: 'url',
              label: '跳转位置',
              type: 'select',
              tips: `选择跳转位置并输入跳转参数可点击跳转`,
              config: {
                options: appRoute,
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'param',
              label: '参数',
              type: 'text',
              config: {
                hidden({ selectedRows, form, initData }) {
                  if (form.location == 'APP') {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
            {
              key: 'display',
              label: '是否展示',
              type: 'radio',
              required: true,
              config: {
                initialValue: 1,
                options: [
                  {
                    text: '是',
                    value: 1
                  },
                  {
                    text: '否',
                    value: 0
                  },
                ]
              }
            },
            {
              key: 'sort',
              width: '130px',
              label: '排序',
              type: 'numeric',
              required: true,
              config: {
                format: 'sort'
              }
            },
            {},
            {},
            {}
          ]
        }
      },
      {
        key: 'lock',
        text: '删除',
        type: 'confirm',
        permission: '/banner/delete',
        disabled: ({ selectedRows }) => {
          return true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/banner/delete'
        }
      },
    ]
  }
};
