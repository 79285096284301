import { citysMenus } from '@/city.js';
import { settleStatus, companyEntity } from '@/utils/public';
export default {
  key: '/agency/applyInstitutions',
  title: '申请机构',
  type: 'table',
  permission: '/agency/institutionsRegistration/list',
  config: {
    checkbox: true,
    dataUrl: '/agency/institutionsRegistration/list',
    filter: {
      initUrl: '/agency/institutionsRegistration/list/get',
      controls: [
        {
          key: 'followUpId',
          label: '跟进人',
          type: 'souSelect',
          config: {
            options: 'employeesList'
          }
        },
        {
          key: 'status',
          label: '状态',
          type: 'select',
          config: {
            options: settleStatus
          }
        },
        {
          key: 'name',
          label: '机构名称',
          type: 'text'
        },
        {
          key: 'city',
          label: '城市',
          type: 'cascader',
          config: {
            options: citysMenus
          }
        },
        {
          key: 'applyTime',
          label: '提交时间',
          type: 'dateRange'
        }
      ]
    },
    columns: [
      {
        key: 'followUpName',
        title: '跟进人',
        width: '100px',
      },
      {
        key: 'registerPhone',
        title: '注册电话',
        width: '100px',
      },
      {
        key: 'registerTime',
        title: '注册时间',
        type: 'date',
        width: '140px',
      },
      {
        key: 'status',
        title: '状态',
        width: '100px',
        type: 'textEnum',
        config: {
          constants: {
            0: '仅注册',
            1: '洽谈中',
            2: '审核中',
            3: '已入驻'
          }
        }
      },
      {
        key: 'companySubject',
        title: '公司主体',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '机构名称',
        width: '100px',
      },
      {
        key: 'provinceCityDistrict',
        title: '城市',
        width: '100px',
      },
      {
        key: 'businessType',
        title: '业务类型',
        width: '100px',
      },
      {
        key: 'contact',
        title: '联系人',
        width: '100px',
      },
      {
        key: 'phoneNumber',
        title: '联系电话',
        width: '100px',
      },
      {
        key: 'applyTime',
        title: '提交时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'inviteInstitutionName',
        title: '推荐机构',
        width: '100px',
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '100px'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      // sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: ''
    },
    actions: [
      {
        key: 'edit',
        text: '设置跟进人',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/agency/institutionsRegistration/setFollowUp',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 家机构，请选择跟进人`
        },
        config: {
          title: '设置跟进人',
          initUrl: '/agency/institutionsRegistration/list/get',
          submitUrl: '/agency/institutionsRegistration/setFollowUp',
          submitText: '确认',
          controls: [
            {
              key: 'followUpId',
              label: '跟进人',
              type: 'souSelect',
              required: true,
              config: {
                options: "employeesList"
              }
            },
          ]
        }
      }
    ],
    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '录入机构',
        type: 'blank',
        permission: '/agency/institutionsRegistration/entry',
        disabled({ selectedRows }) {
          if(selectedRows[0].status == 0 || selectedRows[0].status == 3) {
            return  true;
          } else {
            return false
          }
          
        },
        config: {
          title: '录入机构',
          color: 'primary',
          component: () => import("./views/enterInstitutions")
        }
      },
    ]
  }
};
