export default {
  key: '/system/customerRules',
  title: '客资设置',
  permission: '/system/automaticLoss/list',
  config: {
      component: () => import("./views/customerRulesDetail"),
      actions: [
          {
              key: 'add',
              text: '客资流失设置',
              icon: 'add',
              type: 'form',
              permission: '/system/automaticLoss/edit',
              config: {
                  color: 'primary',
                  title: '客资流失设置',
                  submitUrl: "/system/automaticLoss/edit",
                  submitText: '保存',
                  controls: [

                  ]
              }
          },

      ],
  }
}