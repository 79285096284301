import tree, { disableNode } from "@/utils/tree";
import { intentionLevel, customerStatus } from '@/utils/public';
export default {
  title: '机构客户列表',
  type: 'table',
  key: '/allocationCustomer/allocationCustomer',
  permission: '/agency/allocationCustomer/list',
  config: {
    dataUrl: '/agency/allocationCustomer/list',
    filter: {
      initUrl: '/agency/allocationCustomer/list/get',
      controls: [
        {
          key: 'institutionsCustomerNameContains',
          label: '留服客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'institutionsIdIs',
          label: '所属机构',
          type: 'souSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'createdDate',
          label: '创建时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'phoneNumberContains',
          label: '手机号',
          type: 'text',
          config: {
            rules: 'phoneNumber'
          }
        },
        {
          key: 'wechatContains',
          label: '微信号',
          type: 'text',
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'select',
          config: {
            options: intentionLevel
          }
        },
        {
          key: 'customerStatusIs',
          label: '客户状态',
          type: 'select',
          config: {
            options: customerStatus
          }
        },
        {}
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    columns: [
      {
        key: 'institutionsCustomer.name',
        width: '150px',
        title: '留服客户姓名'
      },
      {
        key: 'attributablePerson.name',
        width: '120px',
        title: '客资归属人'
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '所属机构'
      },
      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'name',
        width: '130px',
        title: '客户姓名'
      },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: '100px',
      },
      {
        key: 'wechat',
        title: '微信号',
        width: '100px',
      },
      {
        key: 'intentionLevel',
        title: '意向等级',
        type: 'enum',
        width: '80px',
        align: "center",
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            },
            null: {
              text: '',
              color: ''
            }
          }
        }
      },
      // {
      //   key: 'userLabelList',
      //   title: '客户标签',
      //   width: 280,
      //   type: 'color',
      //   config: {
      //     textkey: 'content',
      //     colorkey: 'color'
      //   }
      // },
      {
        key: 'attribution.name',
        width: '120px',
        title: '归属人'
      },
      {
        key: 'followUpWithPeople.name',
        width: '120px',
        title: '跟进人'
      },
      {
        key: 'customerStatus',
        title: '客户状态',
        type: 'enum',
        width: '80px',
        align: 'center',
        config: {
          constants: {
            TO_BE_FOLLOWED_UP: {
              text: '待跟进',
              color: 'orange'
            },
            IN_FOLLOW_UP: {
              text: '跟进中',
              color: 'green'
            },
            HAS_BECOME_A_SINGLE: {
              text: '已成单',
              color: 'cyan'
            },
            MULTIPLE_ORDERS: {
              text: '多次成单',
              color: 'red'
            },
            ACCURATE_CUSTOMER_FUNDING: {
              text: '精准客资',
              color: 'red'
            }
          }
        }
      },
      {
        key: 'numberOfFollowUps',
        title: '跟进次数',
        width: '80px',
        align: 'center',
      },
      {
        key: 'institutionsFollowUp.followUpContent',
        title: '最近跟进情况',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'institutionsFollowUp.nextFollowUpTime',
        title: '下次跟进时间',
        type: 'datetime',
        width: '110px',
      },
      {
        key: 'remarks',
        title: '其他信息',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '50px' : '80px'
      }
    ],
    actions: [

    ],
    rowActions: [

      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/allocationCustomer/details',
        config: {
          title: '客户详情',
          component: () => import("./views/agencyCusDetail")
        }
      },

    ]
  }
}
