
export default {
  key: '/content/strategyInfo',
  title: '攻略资讯',
  permission: '/article/v2/list',
  config: {
    component: () => import("./views/strategyInfoDetail"),
    actions: [
      {
        text: '文章列表-列表',
        permission: '/article/v2/list',
      },
      {
        text: '文章列表-新建',
        permission: '/article/v2/add',
      },
      {
        text: '文章列表-删除',
        permission: '/article/v2/delete',
      },
      {
        text: '文章推荐-列表',
        permission: '/article/articleRecommendation/list',
      },
      {
        text: '文章推荐-新建',
        permission: '/article/articleRecommendation/add',
      },
      {
        text: '文章推荐-删除',
        permission: '/article/articleRecommendation/delete',
      },
    ],
    rowActions: [
      {
        text: '文章列表-编辑',
        permission: '/article/v2/edit',
      },
      {
        text: '文章推荐-编辑',
        permission: '/article/articleRecommendation/edit',
      },
    ]
  }

}
