export default {
  key: '/system/operationLog',
  title: '操作日志',
  permission: '/operationLog/list',
  config: {
    component: () => import("./views/operationLog"),
    actions: [
      {
        text: '登录日志列表',
        permission: '/login/log/list',
      },
      {
        text: 'CRM登录日志列表',
        permission: '/login/log/getCrmlist',
      }
    ],
  }
}