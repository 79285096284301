import { intentionLevel, customerStatus } from '@/utils/public';
export default {
  title: '用户列表',
  key: '/registeredUsers/userIntention',
  permission: '/clientUser/admin/getClientUserList',
  config: {
    component: () => import("./views/userIntentionDetail"),
    actions: [
      {
        text: '领取客户',
        permission: '/clientUser/myStudyAbroadIntention/pickUpCustomers',
      },
      {
        text: '分配客户',
        permission: '/clientUser/myStudyAbroadIntention/allocateCustomers',
      },
    ],
  }
}

