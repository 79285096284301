import {citysMenus} from "@/city";

export default {
  key: '/dataReport/institutionalData',
  title: '机构数据',
  type: 'table',
  permission: '/report/institutional/list',
  config: {
    dataUrl: '/report/institutional/list',
    summary: true,
    sticky: true,
    pagination: false,
    scroll: { x: 1200 },
    filter: {
      initUrl: '/report/institutional/list/get',
      controls: [
        {
          key: 'time',
          label: '时间',
          type: 'dateRange',
          resetDisabled: true,
          config: {
            initialValue: '当月',
          }
        },
        {
          key: 'idNotIn',
          label: '排除机构',
          type: 'mSelect',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'provinceCityDistrictContainsIn',
          label: '城市',
          type: 'cascader',
          config: {
            options: citysMenus
          }
        },
        {
          key: 'activateIdIn',
          label: '业务归属人',
          type: 'mSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'attributionIdIn',
          label: '客资归属人',
          type: 'mSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
      ]
    },
    columns: [
      {
        key: 'name',
        title: '机构名',
        width: '150px',
      },
      {
        key: 'city',
        title: '城市',
        type: 'splitCity',
        width: '150px',
      },
      {
        key: 'businessType',
        title: '业务类型',
        width: '150px',
      },
      {
        key: 'receiveCustomerInformation',
        title: '接收客资',
        popover: '根据客资分配时间统计',
        width: '150px',
        summary: true,
      },
      {
        key: 'accurateCustomerFunding',
        title: '精准客资',
        popover: '根据客资分配时间统计',
        width: '150px',
        summary: true,
      },
      {
        key: 'numberOfSignedOrders',
        title: '签单数',
        popover: '根据机构提供的签单时间统计',
        width: '150px',
        summary: true,
      },
      {
        key: 'signedAmount',
        title: '签单额(元)',
        width: '150px',
        summary: true,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'amountToBeRefunded',
        title: '应返金额',
        width: '150px',
        summary: true,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'returnedAmount',
        title: '已返金额',
        popover: '根据收到返佣时间统计',
        width: '150px',
        summary: true,
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },

    ],
    /* 表格排序 */
    sorter: {
    },
    actions: [
      {
        key: 'chapter',
        text: '导出为Excel',
        type: 'url',
        permission: '/report/institutional/export',
        config: {
          title: '导出为Excel',
          color: 'primary',
        },
        options: {
          url: '/api/report/institutional/export',
          params: true,
          newPage: true
        }
      },
    ],
    /* 表格行操作 */
    rowActions: [


    ]
  }
};
