
export default {
  key: '/system/employee',
  title: '账号管理',
  type: 'table',
  permission: '/system/employee/list',
  config: {
    dataUrl: '/system/employee/list',
    filter: {
      initUrl: '/system/employee/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '姓名',
          type: 'text'
        },
        {},
        {}
      ]
    },

    actions: [
      {
        key: 'add',
        text: '添加账号',
        icon: 'plus',
        type: 'form',
        permission: '/system/employee/add',
        config: {
          color: 'primary',
          title: '添加账号',
          initUrl: '/system/employee/add/get',
          submitUrl: '/system/employee/add',
          submitSuccessMessage: "添加成功,默认密码:000000",
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'username',
              label: '用户名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'phoneNumber',
              label: '电话号码',
              type: 'text',
              required: true,
              config: {
                rules: 'phoneNumber'
              }
            },
            {
              key: 'departmentId',
              label: '所属部门',
              type: 'treeSelect',
              config: {
                options: "departments"
              }
            },
            {
              key: 'locked',
              label: '是否锁定',
              type: 'switch'
            },
            {
              key: 'remarks',
              label: '备注',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },

          ]
        }
      }
    ],

    columns: [

      {
        key: 'name',
        width: '100px',
        title: '姓名'
      },
      {
        key: 'username',
        width: '100px',
        title: '用户名'
      },
      {
        key: 'phoneNumber',
        width: '100px',
        title: '电话号码'
      },
      {
        key: 'department.name',
        width: '100px',
        title: '部门'
      },
      {
        key: 'roleList',
        title: '角色',
        width: '150px',
        type: 'textList',
        ckey: 'role.name'
      },
      {
        key: 'locked',
        title: '账号状态',
        type: 'enum',
        width: '80px',
        config: {
          constants: {
            false: {
              text: '正常',
              color: 'orange'
            },
            true: {
              text: '作废',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'createdDate',
        title: '注册时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'remarks',
        title: '备注',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '84px' : '300px'
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: '-id'
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/system/employee/edit',

        config: {
          color: 'primary',
          title: '编辑账号',
          initUrl: '/system/employee/edit/get',
          submitUrl: '/system/employee/edit',
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'username',
              label: '用户名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'phoneNumber',
              label: '电话号码',
              type: 'text',
              required: true,
              config: {
                rules: 'phoneNumber'
              }
            },
            {
              key: 'departmentId',
              label: '所属部门',
              type: 'treeSelect',
              config: {
                options: "departments"
              }
            },
            {
              key: 'locked',
              label: '是否锁定',
              type: 'switch'
            },
            {
              key: 'home',
              label: '账号起始页',
              type: 'radio',
              config: {
                initialValue: false,
                options: [
                  {
                    text: '首页',
                    value: false
                  },
                  {
                    text: '数据概览',
                    value: true
                  },
                ]
              }
            },
            {
              key: 'remarks',
              label: '备注',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },

          ]
        }
      },
      {
        key: 'assigningRoles',
        text: '分配角色',
        type: 'form',
        permission: '/system/employee-role/edit',
        config: {
          color: 'primary',
          title: '分配角色',
          initUrl: "/system/employee-role/edit/get",
          submitUrl: "/system/employee-role/edit",
          submitText: '确认分配',
          controls: [
            {
              key: 'roleIds',
              label: '角色',
              type: 'checkbox',
              required: true,
              config: {
                options: 'roleList',
                layout: 'vertical'
              }
            },
            {}
          ]
        },

      },
      {
        key: 'lock',
        text: '作废',
        type: 'confirm',
        permission: '/system/employee/lock',
        disabled: ({ selectedRows }) => {
          return selectedRows[0].locked === false;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/employee/lock'
        }
      },
      {
        key: 'unlock',
        text: '恢复',
        type: 'confirm',
        permission: '/system/employee/unlock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/employee/unlock'
        }
      },
      {
        key: 'reset-password',
        text: '重置密码',
        type: 'confirm',
        permission: '/system/employee/reset-password',
        disabled: true,
        config: {
          color: 'danger',
          title: '确定对该条记录进行重置密码操作吗？',
          submitUrl: '/system/employee/reset-password',
          submitText: '确认',
          submitColor: 'danger',
          submitSuccessMessage: (submitSuccessMessage) => {
            return `重置密码成功，默认密码：${submitSuccessMessage.response.newPassword}`
          },
          submitLoadingMessage: '正在重置密码 ...'
        }
      },
    ]
  }
};
