export default {
    key: '/dataReport/marketEmployeeData',
    title: '市场员工数据',
    type: 'table',
    permission: '/report/market/list',
    config: {
        dataUrl: '/report/market/list',
        summary: true,
        sticky: true,
        pagination: false,
        scroll: { x: 1200 },
        filter: {
            initUrl: '/report/market/list/get',
            controls: [
                {
                    key: 'time',
                    label: '时间',
                    type: 'dateRange',
                    resetDisabled: true,
                    config: {
                        initialValue: '当月',
                    }
                },
                {
                    key: 'departmentIdIs',
                    label: '部门',
                    type: 'select',
                    config: {
                        options: 'departmentList'
                    }
                },
            ]
        },
        columns: [
            {
                key: 'username',
                title: '姓名',
                width: '150px',
            },
            {
                key: 'departmentName',
                title: '所属部门',
                width: '150px',
            },
            {
                key: 'numberOfEntries',
                title: '录入客资',
                width: '150px',
                summary: true,
            },
            {
                key: 'numberOfEffective',
                title: '已分配客资',
                popover: '根据客资分配时间统计',
                width: '150px',
                summary: true,
            },
            {
                key: 'numberOfAllocationsTd',
                title: '分配藤德客资',
                popover: '已分配客资中，分配给藤德的客资数',
                width: '150px',
                summary: true,
            },
            {
                key: 'accurateCustomerFundingTd',
                title: '藤德精准客资',
                popover: '根据藤德转精准客资时间统计',
                width: '150px',
                summary: true,
            },
            {
                key: 'numberOfAllocations',
                title: '分配客资机构(家)',
                popover: '根据客资分配时间统计',
                width: '150px',
                summary: true,
            },
            {
                key: 'signedAmount',
                title: '机构签单额(元)',
                width: '150px',
                summary: true,
                type: 'numeric',
                config: {
                    format: 'currency'
                }
            },
            {
                key: 'signedAmountTd',
                title: '藤德签单额(元)',
                width: '150px',
                popover: '机构签单额中，藤德的签单金额',
                summary: true,
                type: 'numeric',
                config: {
                    format: 'currency'
                }
            },
            {
                key: 'returnedAmount',
                title: '已返金额(元)',
                popover: '根据收到返佣时间统计',
                width: '150px',
                type: 'modal',
                summary: true,
                config: {
                    format: 'currency',
                    text: (row) => {
                        return (row.returnedAmount*1).toFixed(2) + '元'
                    },
                    title: '',
                    table: true,
                    url: '/report/market/getDetail',
                    filter: 'employeeId',
                    needForm: true,
                    needUrl: true,
                    columns: [
                        {
                            title: '返佣金额',
                            dataIndex: 'returnCommissionAmount',
                            type: 'textCustom',
                            key: 'returnCommissionAmount',
                            config: {
                                text:(val)=>{
                                    return val + '元'
                                }
                            }
                        },
                        {
                            title: '类型',
                            dataIndex: 'paymentType',
                            type: 'textCustom',
                            key: 'paymentType',
                            config: {
                                text:(val)=>{
                                    return '收款'
                                }
                            }
                        },
                        {
                            title: '返佣时间',
                            dataIndex: 'rebateTime',
                            key: 'rebateTime',
                            type: 'datetime'
                        },
                        {
                            title: '签单机构',
                            dataIndex: 'organizationName',
                        },
                        {
                            title: '分配人',
                            dataIndex: 'allocateUsername',
                        },
                        {
                            title: '客户来源',
                            dataIndex: 'customerSource',
                        },
                        {
                            title: '客户姓名',
                            dataIndex: 'customerName',
                        }
                    ],
                }
            },
        ],

        /* 表格排序 */
        sorter: {
            /* 可排序列 */
            sortableColumns: ['id'],
            /* 默认排序 */
            defaultSort: '-id'
        },
        actions: [
            {
                key: 'chapter',
                text: '导出为Excel',
                type: 'url',
                permission: '/report/market/export',
                config: {
                    title: '导出为Excel',
                    color: 'primary',
                },
                options: {
                    url: '/api/report/market/export',
                    params: true,
                    newPage: true
                }
            },
        ],
        /* 表格行操作 */
        rowActions: [


        ]
    }
};
