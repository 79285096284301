import institutionsOrder from "./institutionsOrder";
import transactions from "./transactions";
import cancelorder from "./cancelorder";
import rebate from "./rebate";

export default [
  institutionsOrder,
  cancelorder,
  transactions,
  rebate,
  
]
