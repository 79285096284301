export default {
  title: '角色管理',
  type: 'table',
  key: '/system/role',
  permission: '/system/role/list',
  config: {
    dataUrl: '/system/role/list',
    filter: {
      controls: [
        {
          key: 'nameContains',
          label: '名称',
          type: 'text'
        },
        {},
        {}
      ]
    },
    columns: [
      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'name',
        width: '120px',
        title: '名称'
      },
      {
        key: 'locked',
        title: '是否锁定',
        type: 'enum',
        width: '90px',
        config: {
          constants: {
            true: {
              text: '已锁定',
              color: 'orange'
            },
            false: {
              text: '未锁定',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'description',
        title: '描述',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '230px'
      }
    ],
    actions: [
      {
        key: 'add',
        text: '添加',
        icon: 'plus',
        type: 'form',
        permission: '/system/role/add',
        config: {
          color: 'primary',
          title: '添加角色',
          submitUrl: '/system/role/add',
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '角色名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'description',
              label: '描述',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['id'],
      /* 默认排序 */
      defaultSort: '-id'
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '修改',
        type: 'form',
        icon: 'edit',
        permission: '/system/role/edit',
        config: {
          color: 'primary',
          title: '修改角色',
          initUrl: '/system/role/edit/get',
          submitUrl: '/system/role/edit',
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '角色名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'description',
              label: '描述',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
      {
        key: 'assignPermissions',
        text: '分配权限',
        icon: 'lock',
        type: 'form',
        permission: '/system/role/assign-permissions',
        config: {
          color: 'primary',
          title: '分配权限',
          initUrl: '/system/role/assign-permissions/get',
          submitUrl: '/system/role/assign-permissions',
          submitText: '确认分配',
          controls: [
            {
              key: 'permissions',
              label: '权限',
              type: 'tree',
              required: true,
              config: {
                options({ config }) {
                  return config.menus.map(menu => ({
                    key: menu.key,
                    title: menu.text,
                    scopedSlots: { icon: 'icon' },
                    icon: menu.icon,
                    children: menu.pages.map(pageKey => {
                      const page = config.pages[pageKey];
                      return {
                        key: page.key,
                        title: page.title,
                        icon: page.icon,
                        scopedSlots: { icon: 'icon' },
                        children: [
                          {
                            key: page.permission,
                            title: page.permissionTit ? page.permissionTit : '查看列表'
                          },
                          ...(page.config.actions ?? []).map(action => ({
                            title: action.text,
                            icon: action.icon,
                            scopedSlots: { icon: 'icon' },
                            key: action.permission
                          })),
                          ...(page.config.rowActions ?? []).flatMap(action => (Array.isArray(action) ? action : [action]).map(action => ({
                            title: action.text,
                            icon: action.icon,
                            scopedSlots: { icon: 'icon' },
                            key: action.permission
                          })))
                        ]
                      }
                    })
                  }))
                }
              }
            },
            {}
          ]
        }
      },
      {
        key: 'lock',
        text: '锁定',
        type: 'confirm',
        permission: '/system/role/lock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === false;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/role/lock'
        }
      },
      {
        key: 'unlock',
        text: '开启',
        type: 'confirm',
        permission: '/system/role/unlock',
        disabled({ selectedRows }) {
          return selectedRows[0].locked === true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/system/role/unlock'
        }
      },
    ]
  }
}

