
export default {
  key: '/system/version',
  title: 'App版本管理',
  type: 'table',
  permission: '/system/versionManagement/list',
  config: {
    dataUrl: '/system/versionManagement/list',
    filter: {
      controls: []
    },

    actions: [
      {
        key: 'add',
        text: '新建版本',
        icon: 'plus',
        type: 'form',
        permission: '/system/versionManagement/add',
        config: {
          color: 'primary',
          title: '新建版本',
          submitUrl: '/system/versionManagement/add',
          submitText: '确认添加',
          controls: [
            {
              key: 'platform',
              label: '平台',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: false,
                    text: 'IOS',
                  },
                  {
                    value: true,
                    text: '安卓',
                  },
                ]
              }
            },
            {
              key: 'version',
              label: '版本号',
              type: 'text',
              required: true,
            },
            {
              key: 'miniVersion',
              label: '小版本号',
              type: 'number',
              required: true,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.platform) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
            },
            {
              key: 'downloadUrl',
              label: '下载链接',
              type: 'text',
              required: true,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.platform) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
            },
            {
              key: 'forceUpdate',
              label: '更新策略',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: true,
                    text: '强制更新',
                  },
                  {
                    value: false,
                    text: '非强制更新',
                  },
                ],
              }
            },
            {
              key: 'remind',
              label: '提醒频次',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: true,
                    text: '每次打开提醒',
                  },
                  {
                    value: false,
                    text: '不提醒',
                  },
                ],
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.forceUpdate) {
                    return true;
                  } else {
                    return false;
                  }
                },
              }
            },
            {
              key: 'updateContent',
              label: '更新提示文案',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 100, message: '只能输入1-100个字符' }
                ]
              }
            },
          ]
        }
      }
    ],

    columns: [
      {
        key: 'platform',
        width: '100px',
        title: '平台',
        type: 'textCustom',
        config: {
          text: (val) => {
            return val ? '安卓' : 'IOS'
          }
        }
      },
      {
        key: 'version',
        width: '100px',
        title: '版本号'
      },
      {
        key: 'forceUpdate',
        width: '100px',
        title: '更新策略',
        type: 'textCustom',
        config: {
          text: (val) => {
            return val ? '强制更新' : '非强制更新'
          }
        }
      },
      {
        key: 'updateContent',
        width: '300px',
        title: '更新提示文案',
        ellipsis: true
      },
      {
        key: 'createdDate',
        width: '100px',
        title: '创建时间',
        type: 'date'
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: '84px'
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        permission: '/system/versionManagement/edit',
        config: {
          color: 'primary',
          title: '编辑版本',
          initUrl: '/system/versionManagement/edit/get',
          submitUrl: '/system/versionManagement/edit',
          submitText: '确认添加',
          controls: [
            {
              key: 'platform',
              label: '平台',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: false,
                    text: 'IOS',
                  },
                  {
                    value: true,
                    text: '安卓',
                  },
                ]
              }
            },
            {
              key: 'version',
              label: '版本号',
              type: 'text',
              required: true,
            },
            {
              key: 'miniVersion',
              label: '小版本号',
              type: 'number',
              required: true,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.platform) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
            },
            {
              key: 'downloadUrl',
              label: '下载链接',
              type: 'text',
              required: true,
              config: {
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.platform) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
            },
            {
              key: 'forceUpdate',
              label: '更新策略',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: true,
                    text: '强制更新',
                  },
                  {
                    value: false,
                    text: '非强制更新',
                  },
                ]
              }
            },
            {
              key: 'remind',
              label: '提醒频次',
              type: 'select',
              required: true,
              config: {
                options: [
                  {
                    value: true,
                    text: '每次打开提醒',
                  },
                  {
                    value: false,
                    text: '不提醒',
                  },
                ],
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.forceUpdate) {
                    return true;
                  } else {
                    return false;
                  }
                },
              }
            },
            {
              key: 'updateContent',
              label: '更新提示文案',
              type: 'textarea',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 100, message: '只能输入1-100个字符' }
                ]
              }
            },
          ]
        }
      }
    ]
  }
};
