import { payStatus } from '@/utils/public';
export default {
  key: '/order/transactions',
  title: '收支记录',
  type: 'table',
  permission: '/order/transactions/list',
  config: {
    dataUrl: '/order/transactions/list',
    filter: {
      initUrl: '/order/transactions/list/get',
      controls: [
        {
          key: 'institutionsIdIs',
          label: '签单机构',
          type: 'souSelect',
          config: {
            options: "institutionsList"
          }
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'paymentTypeIs',
          label: '类型',
          type: 'select',
          config: {
            options: payStatus
          }
        },
        {
          key: 'time',
          label: '收支时间',
          type: 'dateRange'
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出收支记录',
        type: 'url',
        permission: '/order/transactions/export',
        config: {
          title: '导出收支记录',
          color: 'primary',
        },
        options: {
          url: '/api/order/transactions/export',
          params: true,
          newPage: true
        }
      },
    ],

    columns: [
      {
        key: 'orderRecord.orderNumber',
        width: '150px',
        title: '订单号',
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '签单机构',
        ellipsis: true
      },
      {
        key: 'institutionsCustomerRelationship.name',
        width: '100px',
        title: '客户姓名',
      },
      {
        key: 'paymentType',
        title: '类型',
        type: 'enum',
        width: '65px',
        align: "center",
        config: {
          constants: {
            PAYMENT: {
              text: '支付',
              color: 'orange'
            },
            REFUND: {
              text: '退款',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'time',
        title: '收支时间',
        width: '100px',
        type: 'datetime',
      },
      {
        key: 'amount',
        title: '金额',
        width: '180px',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'attributable.name',
        width: '100px',
        title: '订单归属人',
      },
      {
        key: 'followUp.name',
        width: '100px',
        title: '订单跟进人',
      },
      {
        key: 'operator.name',
        width: '100px',
        title: '操作人',
      },
      {
        key: 'createdDate',
        title: '操作时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        width: 80,
        type: 'actions'
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['time'],
      /* 默认排序 */
      defaultSort: '-time'
    },

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '订单详情',
        type: 'blank',
        permission: '/order/transactions/details',
        config: {
          title: '查看订单详情',
          color: 'primary',
          component: () => import("./views/transactionsDetail")
        }
      }
    ]
  }
};
