export default {
  key: '/system/sortingRules',
  title: '排序规则',
  permission: '/sortrule/operation/list',
  config: {
    component: () => import("./views/sortingRulesDetail"),
    actions: [
      {
        text: '权重设置',
        permission: '/sortrule/weight/edit',
      }
    ],
    rowActions: [
      {
        text: '编辑运营系数',
        permission: '/sortrule/operation/edit',
      }
    ]
  }
}