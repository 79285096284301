export default {
  title: '专业库',
  type: 'table',
  key: '/college/major',
  permission: '/professional/list',
  config: {
    dataUrl: '/professional/list',
    filter: {
      initUrl: '/professional/getProfessionalType',
      controls: [
        {
          key: 'professionalName',
          label: '专业名称',
          type: 'text'
        },
        {
          key: 'professionalClassificationManagementId',
          label: '分类',
          type: 'select',
          config: {
            options: 'data',
            textKey: 'professionalClassification',
            valueKey: 'id'
          }
        },
        {}
      ]
    },
    columns: [
      {
        key: 'professionalName',
        width: '150px',
        title: '专业名'
      },
      {
        key: 'classificationName',
        width: '150px',
        title: '分类'
      },
      {
        key: 'professionalDesc',
        width: '300px',
        title: '专业简介',
        ellipsis: true
      },

      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
      }
    ],
    actions: [
      {
        key: 'add',
        text: '新建专业',
        icon: 'plus',
        type: 'form',
        permission: '/professional/add',
        config: {
          color: 'primary',
          title: '新建专业',
          submitUrl: '/professional/add',
          initUrl: '/professional/getProfessionalType',
          submitText: '确认添加',
          controls: [
            {
              key: 'professionalName',
              label: '专业名',
              type: 'text',
              required: true,
            },
            {
              key: 'majorEnglishName',
              label: '专业英文名',
              type: 'spaceText',
              required: true,
            },
            {
              key: 'professionalClassificationManagementId',
              label: '专业分类',
              type: 'select',
              required: true,
              config: {
                options: 'data',
                textKey: 'professionalClassification',
                valueKey: 'id'
              }
            },
            {
              key: 'professionalDesc',
              label: '专业简介',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 800, message: '只能输入1-800个字符' }
                ]
              }
            },
          ]
        }
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        type: 'form',
        icon: 'edit',
        permission: '/professional/update',
        config: {
          color: 'primary',
          title: '编辑',
          initUrl: '/professional/update/get',
          submitUrl: '/professional/update',
          submitText: '确认',
          otherUrls: [
            {
              url: '/professional/getProfessionalType',
              nameKey: 'data',
              type: 'select'
            }
          ],
          controls: [
            {
              key: 'professionalName',
              label: '专业名',
              type: 'text',
              required: true,
            },
            {
              key: 'majorEnglishName',
              label: '专业英文名',
              type: 'spaceText',
              required: true,
            },
            {
              key: 'professionalClassificationManagementId',
              label: '专业分类',
              type: 'select',
              required: true,
              config: {
                options: 'data',
                textKey: 'professionalClassification',
                valueKey: 'id'
              }
            },
            {
              key: 'professionalDesc',
              label: '专业简介',
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 800, message: '只能输入1-800个字符' }
                ]
              }
            },
          ]
        }
      },
    ]
  }
}

