
import { gender, serviceItems, serviceNewItems, hignEducation, languageType, intentionLevel, customerStage, customerStageNew, country, customerSource, starRating, highSeasReasons } from '@/utils/public';
export default {
  title: '留服客户列表',
  type: 'table',
  key: '/customer/institutionsCustomer',
  permission: '/agency/institutionsCustomer/list',
  config: {
    dataUrl: '/agency/institutionsCustomer/list',
    filter: {
      initUrl: '/agency/institutionsCustomer/list/get',
      controls: [
        {
          key: 'createdDate',
          label: '创建时间',
          type: 'dateRange'
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'phoneNumberContains',
          label: '手机号',
          type: 'text',
          config: {
            rules: 'phoneNumber'
          }
        },
        {
          key: 'wechatContains',
          label: '微信号',
          type: 'text',
        },
        {
          key: 'intentionLevelIs',
          label: '意向等级',
          type: 'select',
          config: {
            options: intentionLevel
          }
        },
        {
          key: 'intendedCountriesContainsIn',
          label: '意向国家',
          type: 'select',
          width: '260',
          config: {
            options: country
          }
        },
        {
          key: 'customerSourceIs',
          label: '客户来源',
          type: 'select',
          width: '260',
          config: {
            options: customerSource
          }
        },
        {
          start: 'budgetOnOrAfter',
          end: 'budgetOnOrBefore',
          label: '预算范围',
          type: 'numberRange',
        },
        {
          key: 'customerPhaseIn',
          label: '客户阶段',
          type: 'mSelect',
          width: '260',
          config: {
            options: customerStageNew
          }
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'mSelect',
          width: '260',
          config: {
            options: "employeesList"
          }
        },
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['createdDate'],
      /* 默认排序 */
      defaultSort: '-createdDate'
    },
    checkbox: true,
    columns: [
      {
        key: 'createdDate',
        title: '创建时间',
        type: 'datetime',
        width: '100px',
      },
      {
        key: 'attributablePerson.name',
        title: '客资归属人',
        width: '100px',
      },
      {
        key: 'name',
        title: '客户姓名',
        width: '150px',
      },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: '100px',
      },
      {
        key: 'wechat',
        title: '微信号',
        width: '100px',
      },
      {
        key: 'customerPhase',
        title: '客户阶段',
        type: 'textEnum',
        width: '100px',
        config: {
          constants: {
            NEW_CUSTOMER_CAPITAL: '新客咨',
            CONTACTS: '已联系上',
            IN_INTENTION: '确定意向中',
            TO_BE_ALLOCATED: '待分配',
            ALLOCATED: '已分配',
            MULTIPLE_ASSIGNMENT: '多次分配',
          }
        }
      },
      {
        key: 'intendedCountries',
        title: '意向国家',
        width: '150px',
        config: {
          options: country
        }
      },
      {
        key: 'budget',
        title: '预算(万)',
        width: '100px',
      },
      {
        key: 'intentionLevel',
        title: '意向等级',
        type: 'enum',
        width: '80px',
        align: "center",
        config: {
          constants: {
            HIGH: {
              text: '高',
              color: 'orange'
            },
            MEDIUM: {
              text: '中',
              color: 'green'
            },
            LOW: {
              text: '低',
              color: 'red'
            },
            INVALID: {
              text: '无效',
              color: 'grey'
            }
          }
        }
      },
      {
        key: 'remarks',
        title: '其他信息',
        width: '200px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '70px' : '150px'
      }
    ],
    actions: [
      {
        key: 'add',
        text: '添加',
        icon: 'plus',
        type: 'form',
        isHidden: true,
        permission: '/agency/institutionsCustomer/add',
        config: {
          color: 'primary',
          title: '添加客户',
          // initUrl: '/agency/institutionsCustomer/add/get',
          submitUrl: '/agency/institutionsCustomer/add',
          submitText: '确认添加',
          showDialog: true,
          controls: [
            {
              key: 'allocationTd',
              label: '客户分配',
              type: 'radio',
              required: true,
              config: {
                options: [
                  {
                    text: '分配给藤德留学',
                    value: true,
                  },
                  {
                    text: '不确定',
                    value: false,
                  },
                ]
              }
            },
            {},
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'customerSource',
              label: '客户来源',
              required: true,
              type: 'select',
              config: {
                options: customerSource
              }
            },
            {
              key: 'wechat',
              label: '微信号',
              type: 'text',
              blur: true,
              blurconfig: {
                title: '提示',
                submitUrl: '/agency/institutionsCustomer/wechat',
                key: "wechat",
                blurMessage: (res) => {
                  return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户阶段为 ${{
                      NEW_CUSTOMER_CAPITAL: "新客咨",
                      CONTACTS: "已联系上",
                      IN_INTENTION: "确定意向中",
                      TO_BE_ALLOCATED: "待分配",
                      ALLOCATED: '已分配',
                      MULTIPLE_ASSIGNMENT: "多次分配",
                    }[res.customerPhase]}，创建时间：${res.createdDate.split('T')[0]}`
                },
              },
              config: {
                rules: ['wechat']
              }
            },
            {
              key: 'phoneNumber',
              label: '手机号',
              type: 'text',
              blur: true,
              blurconfig: {
                title: '提示',
                submitUrl: '/agency/institutionsCustomer/phoneNumber',
                key: "phoneNumber",
                blurMessage: (res) => {
                  return `客户与  ${res.entryPerson} 添加的 ${res.institutionsCustomer}  重复！ 当前客资归属人 ${res.attributablePerson} ， 
                  客户阶段为 ${{
                      NEW_CUSTOMER_CAPITAL: "新客咨",
                      CONTACTS: "已联系上",
                      IN_INTENTION: "确定意向中",
                      TO_BE_ALLOCATED: "待分配",
                      ALLOCATED: '已分配',
                      MULTIPLE_ASSIGNMENT: "多次分配",
                    }[res.customerPhase]}，创建时间：${res.createdDate.split('T')[0]}`
                },
              },
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'consultingProject',
              label: '咨询项目',
              type: 'mSelect',
              dealData: true,
              required: true,
              config: {
                options: serviceNewItems
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'customerPhase',
              label: '客户阶段',
              type: 'steps',
              config: {
                options: customerStage
              }
            },
            {
              key: 'star',
              label: '客户星级',
              required: false,
              type: 'select',
              config: {
                options: starRating
              }
            },
            {
              key: 'budget',
              label: '预算(人民币)',
              type: 'numeric',
              config: {
                format: "money"
              }
            },
            {
              key: 'intendedCountries',
              dealData: true,
              limitNum: 3,
              label: '意向国家',
              type: 'mSelect',
              config: {
                options: country
              }
            },
            {
              key: 'gender',
              label: '性别',
              required: false,
              type: 'select',
              config: {
                options: gender
              }
            },
            {
              key: 'residentCity',
              label: '常驻城市',
              type: 'text',
            },
            {
              key: 'highestDegree',
              label: '最高学历',
              type: 'select',
              config: {
                options: hignEducation
              }
            },
            {
              key: 'college',
              label: '就读院校',
              type: 'text',
            },
            {
              key: 'studyProfessional',
              label: '就读专业',
              type: 'text',
            },
            {
              key: 'gpa',
              label: 'GPA',
              type: 'text',
            },
            {
              key: 'languageGradeType',
              label: '语言成绩类型',
              type: 'select',
              config: {
                options: languageType
              }
            },
            {
              key: 'languageAchievement',
              label: '语言成绩',
              type: 'text',
            },
            {
              key: 'graduationTime',
              label: '毕业时间',
              type: 'date',
            },
            {
              key: 'remarks',
              label: '其他信息',
              type: 'textarea',
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '分配机构',
        icon: 'plus',
        type: 'blank',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/agency/institutionsCustomer/allocation',
        modalObj: (rowData, tableData) => {
          let obj = {};
          if (rowData.length > 1) {
            let allocatedArr = rowData.filter(item => {
              return item.customerPhase === 'TO_BE_ALLOCATED'
            });
            if (allocatedArr.length === rowData.length) {
              obj.showMOdal = false
            } else {
              obj.showMOdal = true;
              let custom = [];
              rowData.map((item) => {
                if (item.customerPhase != 'TO_BE_ALLOCATED') {
                  custom.push(item.name)
                }
              })
              obj.title = '无法进行分配',
                obj.content = `<p>以下客户已经分配了机构，或客户阶段不是【待分配】，无法进行批量分配。请对这些客户进行单独分配。</p>
                <p style="color:#999999">（批量分配机构的客户，客户阶段必须是【待分配】）</p>
                <p style="border: 1px solid #eeeeee; border-radius: 4px; padding: 4px 2px; margin-top: 4px;">${custom.join("，")}</p>`
            }
          } else {
            obj.showMOdal = false
          }
          return obj
        },
        config: {
          title: '分配机构',
          component: () => import("./views/allocationEdit")
        }
        // config: {
        //   color: 'primary',
        //   title: '分配机构',
        //   initUrl: '/agency/institutionsCustomer/allocation/get',
        //   submitUrl: '/agency/institutionsCustomer/allocation',
        //   submitText: '分配',
        //   controls: [
        //     {
        //       key: 'country',
        //       label: '意向国家',
        //       type: 'select',
        //       isCascade: true,
        //       cascadeData: {
        //         cascadeUrl: '/agency/institutionsCustomer/acquisitionAgency/get',
        //         cascadeKey: 'institutionsId'
        //       },
        //       config: {
        //         options: country
        //       }
        //     },
        //     {
        //       key: 'institutionsId',
        //       label: '筛选机构',
        //       type: 'transfer',
        //       required: true,
        //       cascadeData: {
        //         cascadeUrl: '/agency/institutionsCustomer/acquisitionAgency/get',
        //         cascadeKey: 'institutionsId'
        //       },
        //       config: {
        //         options: [],
        //         targetKeys: [],
        //         source: '可选机构',
        //         target: '已选',
        //       }
        //     }
        //   ]
        // }
      },
      {
        key: 'edit',
        text: '修改客资归属人',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/agency/institutionsCustomer/setAttributablePerson',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 家机构，请选择客资归属人`
        },
        config: {
          title: '修改客资归属人',
          initUrl: '/agency/institutionsCustomer/setAttributablePerson/get',
          submitUrl: '/agency/institutionsCustomer/setAttributablePerson',
          submitText: '确认',
          controls: [
            {
              key: 'attributablePersonId',
              label: '客资归属人',
              type: 'souSelect',
              required: true,
              config: {
                options: "employeesList"
              }
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '分配给藤德',
        icon: 'plus',
        type: 'dialog',
        isSelected: true,  // 是否必勾选
        determine: true,  // 是否需要判定条
        permission: '/agency/institutionsCustomer/exclusiveAllocation',
        modalObj: (rowData, tableData) => {
          return `您共选择 ${rowData.length} 个客户，请选择对应的藤德客资归属人`
        },
        config: {
          title: '分配藤德客资归属人',
          initUrl: '/agency/institutionsCustomer/exclusiveAllocation/get',
          submitUrl: '/agency/institutionsCustomer/exclusiveAllocation',
          submitText: '确认',
          receive: true,
          submitSuccessMessage: (submitSuccessMessage) => {
            console.log(submitSuccessMessage);
            if (submitSuccessMessage.response.message) {
              return `${submitSuccessMessage.response.message}`
            } else {
              return '分配成功'
            }

          },
          controls: [
            {
              key: 'assignedOwnershipId',
              label: '藤德客资归属人',
              type: 'souSelect',
              required: true,
              config: {
                options: "employees"
              },
              isFillingIn: true
            },
            {
              key: 'allocationType',
              label: '跟进人分配方式',
              type: 'radio',
              config: {
                options: [
                  {
                    text: '自动分配',
                    value: 'AUTOMATIC',
                    noshow: ({ source }) => {
                      return source == 'MANUAL';
                    }
                  },
                  {
                    text: '手动分配',
                    value: 'MANUAL',
                  },
                ]
              }
            },
            {
              key: 'selectedDepartmentId',
              label: '分配部门',
              type: 'select',
              required: true,
              config: {
                options: "selectedDepartmentId",
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.allocationType == 'AUTOMATIC' && initData.selectedDepartmentId !== undefined) {
                    return false;
                  } else {
                    return true;
                  }
                },
              },
            },
            {
              key: 'followUpPeopleId',
              label: '跟进人',
              type: 'souSelect',
              required: true,
              config: {
                options: "followUpPeople",
                isAssociate: true,
                hidden({ selectedRows, form, initData }) {
                  if (form.allocationType == 'MANUAL') {
                    return false;
                  } else {
                    return true;
                  }
                },
              }
            },
          ]
        }
      },
      {
        key: 'edit',
        text: '移入公海',
        icon: 'edit',
        type: 'form',
        isSelected: true,
        permission: '/agency/institutionsCustomer/highSeas',
        config: {
          color: 'primary',
          title: '移入公海',
          info: (rows) => {
            return `您共选择 ${rows.length} 条客资，是否全部移入公海中`
          },
          // initUrl: '/institutions/customer/setFollowUpPeople/get',
          submitUrl: '/agency/institutionsCustomer/highSeas',
          submitText: '确定',
          controls: [
            {
              key: 'moveInReason',
              label: '移入原因',
              type: 'select',
              required: true,
              config: {
                options: highSeasReasons
              }
            },
            {}
          ]
        }
      },
      {
        key: 'detail',
        text: '客户导入',
        icon: '',
        type: 'blank',
        permission: '/agency/institutionsCustomer/uploadCustomer',
        config: {
          title: '客户导入',
          color: 'primary',
          component: () => import("./views/customerImport")
        }
      },
    ],
    listFilter: {
      key: 'quickCheck',
      defaultValue: 'all',
      options: [
        {
          value: 'all',
          name: '全部客资'
        },
        {
          value: 'toBeAllocated',
          name: '可重新分配客资',
          tips: '显示可以重新分配机构的藤德客资，定义如下:分配给藤德留学的客资，在藤德CRM被放入公海'
        },
      ]
    },
    rowActions: [
      {
        key: 'edit',
        text: '编辑',
        icon: 'edit',
        type: 'form',
        permission: '/agency/institutionsCustomer/edit',
        config: {
          color: 'primary',
          title: '编辑客户信息',
          initUrl: "/agency/institutionsCustomer/edit/get",
          submitUrl: "/agency/institutionsCustomer/edit",
          submitText: '确认修改',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'customerSource',
              label: '客户来源',
              required: true,
              type: 'select',
              config: {
                options: customerSource
              }
            },

            {
              key: 'wechat',
              label: '微信号',
              type: 'text',
              config: {
                rules: ['wechat']
              }
            },
            {
              key: 'phoneNumber',
              label: '手机号',
              type: 'text',
              config: {
                rules: ['phoneNumber']
              }
            },
            {
              key: 'consultingProject',
              label: '咨询项目',
              dealData: true,
              type: 'mSelect',
              required: true,
              config: {
                options: serviceNewItems
              }
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'customerPhase',
              label: '客户阶段',
              type: 'steps',
              config: {
                options: customerStage
              }
            },
            {
              key: 'star',
              label: '客户星级',
              required: false,
              type: 'select',
              config: {
                options: starRating
              }
            },
            {
              key: 'budget',
              label: '预算(人民币)',
              type: 'numeric',
              config: {
                format: "money"
              }
            },
            {
              key: 'intendedCountries',
              label: '意向国家',
              dealData: true,
              limitNum: 3,
              type: 'mSelect',
              config: {
                options: country
              }
            },
            {
              key: 'gender',
              label: '性别',
              required: false,
              type: 'select',
              config: {
                options: gender
              }
            },
            {
              key: 'residentCity',
              label: '常驻城市',
              type: 'text',
            },
            {
              key: 'highestDegree',
              label: '最高学历',
              type: 'select',
              config: {
                options: hignEducation
              }
            },
            {
              key: 'college',
              label: '就读院校',
              type: 'text',
            },
            {
              key: 'studyProfessional',
              label: '就读专业',
              type: 'text',
            },
            {
              key: 'gpa',
              label: 'GPA',
              type: 'text',
            },
            {
              key: 'languageGradeType',
              label: '语言成绩类型',
              type: 'select',
              config: {
                options: languageType
              }

            },
            {
              key: 'languageAchievement',
              label: '语言成绩',
              type: 'text',
            },
            {
              key: 'graduationTime',
              label: '毕业时间',
              type: 'date',
            },
            {
              key: 'remarks',
              label: '其他信息',
              type: 'textarea',
            },
          ]
        }
      },
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/institutionsCustomer/details',
        config: {
          title: '客户详情',
          component: () => import("./views/institutionsCustomerDetail")
        }
      },
      {
        key: 'add',
        text: '跟进',
        icon: 'add',
        type: 'form',
        permission: '/agency/institutionsCustomer/followUpWithCustomers',
        config: {
          color: 'primary',
          title: '新增跟进记录',
          initUrl: "/agency/institutionsCustomer/edit/get",
          submitUrl: "/agency/institutionsCustomer/followUpWithCustomers",
          submitText: '确认添加',
          controls: [
            {
              key: 'name',
              label: '客户姓名',
              type: 'text',
              required: true,
              disabled: true,
              config: {
                rules: [
                  { min: 1, max: 30, message: '只能输入1-30个字符' }
                ]
              }
            },
            {
              key: 'followUpTime',
              label: '跟进时间',
              required: true,
              showNow: true,
              type: 'date',
            },
            {
              key: 'intentionLevel',
              label: '意向等级',
              required: true,
              type: 'select',
              config: {
                options: intentionLevel
              }
            },
            {
              key: 'followUpContent',
              label: '跟进内容',
              required: true,
              type: 'textarea',
              config: {
                rules: [
                  { min: 1, max: 500, message: '只能输入1-500个字符' }
                ]
              }
            },
          ]
        }
      },
      // {
      //   key: 'edit',
      //   text: '分配机构',
      //   icon: 'edit',
      //   type: 'form',
      //   permission: '/agency/institutionsCustomer/allocation',
      //   config: {
      //     color: 'primary',
      //     title: '分配机构',
      //     initUrl: "/agency/institutionsCustomer/allocation/get",
      //     submitUrl: "/agency/institutionsCustomer/allocation",
      //     submitText: '确认分配',
      //     controls: [
      //       {
      //         key: 'institutionsId',
      //         label: '选择机构',
      //         type: 'mSelect',
      //         disabled: true,
      //         config: {
      //           options: "institutionsList"
      //         }
      //       },
      //       {

      //       },
      //     ]
      //   }
      // },
    ]
  }
}
