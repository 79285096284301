import axios from "axios";
import router from "@/router/index";
import {paramsSerializer} from "@/utils/core";

const baseURL = '/api';

const instance = axios.create({
  baseURL,
  timeout: 60000,
  paramsSerializer
});

instance.interceptors.response.use(response => response.data, async error => {
  const response = error.response;
  if (response) {
    // 需要授权登录
    if (response.status === 401) {
      await router.push('/login');
      return Promise.reject({
        message: '请先登录',
        status: response.status
      });
    }
    return Promise.reject(response.data.message != null
      ? response.data
      : {
        message: `${response.status}：${response.statusText}`,
        status: response.status
      }
    );
  }
  if (error.request) {
    return Promise.reject({message: '网络连接出错'}); // 请求失败（网络错误）
  }
  return Promise.reject({message: error.message}); // 其他错误
});

export function get(path, params) {
  return instance.get(path, {
    params
  });
}

export function post(path, data,config) {
  return instance.post(path, data instanceof FormData ? data : paramsSerializer(data),config);
}


export function postRequest(path, data, config) {
  return instance.post(path, data,config);
}
 
//用于导出excel表格
export const exportExcel = ({ method = 'get', url, data = {}, fileName }) => {
    const field = method === 'get' ? 'params' : 'data';
    axios({
        method,
        url,
        [field]: data,
        responseType: 'blob'
    })
        .then((res) => {
            //导出接口失败 返回的也是blob type为application/json
            if (res.data?.type === 'application/json') throw new Error();
 
            const blob = new Blob([res.data], {
                type: 'application/vnd.ms-excel'
            });
            if ('download' in document.createElement('a')) {
                // 非IE浏览器下载
                // 创建a标签
                const link = document.createElement('a');
                // 规定下载的超链接
                link.setAttribute('download', `${fileName}.xls`);
                // 未点击前隐藏a链接
                link.style.display = 'none';
                // 创建URL对象，指向该文件url
                link.href = URL.createObjectURL(blob);
                // 将a标签添加到dom中
                document.body.append(link);
                // 触发a标签点击事件
                link.click();
                // 释放之前的URL对象
                URL.revokeObjectURL(link.href);
                // 从dom中移除该a链接
                document.body.removeChild(link);
            } else {
                // IE10+ 下载
                navigator.msSaveBlob(blob, filename);
            }
            console.log('导出成功');
        })
        .catch(() => {
            console.log('导出失败');
        });
};
