import { rebateType } from '@/utils/public';
export default {
  key: '/order/rebate',
  title: '返佣记录',
  type: 'table',
  permission: '/approval/rebatesRecord/list',
  config: {
    dataUrl: '/approval/rebatesRecord/list',
    filter: {
      initUrl: '/approval/rebatesRecord/list/get',
      controls: [
        {
          key: 'institutionsIdIs',
          label: '签单机构',
          type: 'souSelect',
          config: {
            options: "institutionsList"
          }
        },
        {
          key: 'activateIdIs',
          label: '业务归属人',
          type: 'souSelect',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'attributablePersonIdIs',
          label: '客资归属人',
          type: 'souSelect',
          config: {
            options: "employeesList"
          }
        },
        {
          key: 'paymentTypeIs',
          label: '类型',
          type: 'select',
          config: {
            options: rebateType
          }
        },
        {
          key: 'rebateTime',
          label: '返佣时间',
          type: 'dateRange'
        },{
          key: 'operationTime',
          label: '操作时间',
          type: 'dateRange'
        },
      ]
    },

    /* 表格操作 */
    actions: [
      {
        key: 'chapter',
        text: '导出返佣记录',
        type: 'url',
        permission: '/approval/rebatesRecord/export',
        config: {
          title: '导出返佣记录',
          color: 'primary',
        },
        options: {
          url: '/api/approval/rebatesRecord/export',
          params: true,
          newPage: true
        }
      },
    ],

    columns: [
      {
        key: 'orderRecord.orderNumber',
        width: '150px',
        title: '订单号',
      },
      {
        key: 'institutions.name',
        width: '150px',
        title: '签单机构',
      },
      {
        key: 'institutionsCustomerRelationship.name',
        width: '100px',
        title: '客户姓名',
      },
      {
        key: 'paymentType',
        title: '类型',
        type: 'enum',
        width: '65px',
        align: "center",
        config: {
          constants: {
            RECEIPT: {
              text: '收款',
              color: 'orange'
            },
            REFUND: {
              text: '退款',
              color: 'green'
            }
          }
        }
      },
      {
        key: 'rebateTime',
        title: '返佣时间',
        width: '100px',
        type: 'datetime',
      },
      {
        key: 'returnCommissionAmount',
        title: '返佣金额',
        width: '150px',
        type: 'numeric',
        config: {
          format: 'currency'
        }
      },
      {
        key: 'bizAttributionName',
        width: '100px',
        title: '业务归属人',
      },
      {
        key: 'customerAttributionName',
        width: '100px',
        title: '客资归属人',
      },
      {
        key: 'staffName',
        width: '100px',
        title: '客服',
      },
      {
        key: 'customerSource',
        width: '100px',
        title: '来源渠道',
      },
      {
        key: 'operator.username',
        width: '100px',
        title: '操作人',
      },
      {
        key: 'createdDate',
        title: '操作时间',
        type: 'datetime',
        width: '100px',
      }
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['rebateTime'],
      /* 默认排序 */
      defaultSort: '-rebateTime'
    },

    /* 表格行操作 */
    rowActions: [

    ]
  }
};
