export default {
  key: '/approvalCenter/institutionalCooperationReview',
  title: '机构合作审核',
  permission: '/agency/institutionsAudit/list',
  config: {
    component: () => import("./views/institutionalCooperationReview"),
    actions: [
      // {
      //   text: '列表',
      //   permission: '/agency/institutionsAudit/list',
      // },
      {
        text: '初审通过',
        permission: '/agency/institutionsAudit/prePass',
      },
      {
        text: '终审通过',
        permission: '/agency/institutionsAudit/pass',
      },
      {
        text: '拒绝',
        permission: '/agency/institutionsAudit/reject',
      },
    ],
    rowActions: [
      {
        text: '详情',
        permission: '/agency/institutionsAudit/get',
      },
      {
        text: '已通过-详情',
        permission: '/agency/institutionsAudit/getInstitutions',
      }
    ]
  }

}

