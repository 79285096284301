import { platform, conversionTarget, appointTranfser } from '@/utils/public';
export default {
  key: '/registeredUsers/storeData',
  title: '门店数据',
  type: 'table',
  permission: '/report/orgShop/event/list',
  config: {
    dataUrl: '/report/orgShop/event/list',
    setPageSize: 100,
    summary: true,
    sticky: true,
    filter: {
      controls: [
        {
          key: 'time',
          label: '统计时间',
          type: 'dateRange',
        },
        {
          key: 'orgName',
          label: '门店',
          type: 'text'
        }
      ]
    },

    /* 表格操作 */
    actions: [
      
    ],

    columns: [
      {
        key: 'orgName',
        width: '150px',
        title: '门店',
      },
      {
        key: 'scanNumber',
        width: '150px',
        title: '曝光量',
        summary: true,
      },
      {
        key: 'clickNumber',
        width: '150px',
        title: '点击量',
        summary: true,
      },
      {
        key: 'reservationNumber',
        width: '150px',
        title: '预约数',
        summary: true,
      },
      // {
      //   key: 'openTime',
      //   width: '150px',
      //   title: '开通时间',
      //   type: 'datetime'
      // },
    ],

    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: [''],
      /* 默认排序 */
      defaultSort: ''
    },

    /* 表格行操作 */
    rowActions: [
    ]
  }
};
