
export default {
  key: '/customer/followup',
  title: '内部跟进记录',
  type: 'table',
  permission: '/agency/followUpRecords/list',
  config: {
    dataUrl: '/agency/followUpRecords/list',
    filter: {
      initUrl: '/agency/followUpRecords/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'followUpPeopleIdIs',
          label: '跟进人',
          type: 'souSelect',
          config: {
            options: 'employeesList'
          }
        },
        {},{}
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['followUpTime'],
      /* 默认排序 */
      defaultSort: '-followUpTime'
    },
    actions: [

    ],

    columns: [
      {
        key: 'institutionsCustomer.name',
        width: '150px',
        title: '客户姓名'
      },
      {
        key: 'followUpPeople.name',
        width: '150px',
        title: '跟进人'
      },
      {
        key: 'followUpTime',
        title: '跟进时间',
        type: 'datetime',
        width: '150px',
      },
      {
        key: 'followUpContent',
        title: '跟进内容',
        ellipsis: true,
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '50px' : '120px'
      }
    ],

    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/followUpRecords/details',
        config: {
          title: '客户详情',
          component: () => import("./views/followupCusDetail")
        }
      },
    ]
  }
};
