import { customerSource, highSeasReasons } from '@/utils/public';
export default {
  title: '机构客户公海',
  type: 'table',
  key: '/allocationCustomer/highSeas',
  permission: '/agency/highSeas/list',
  config: {
    dataUrl: '/agency/highSeas/list',
    filter: {
      initUrl: '/agency/highSeas/list/get',
      controls: [
        {
          key: 'nameContains',
          label: '客户姓名',
          type: 'text'
        },
        {
          key: 'institutionsIdIs',
          label: '所属机构',
          type: 'select',
          config: {
            options: 'institutionsList'
          }
        },
        {
          key: 'moveInReasonIs',
          label: '移入原因',
          type: 'select',
          config: {
            options: highSeasReasons
          }
        },
        {
          key: 'sourceChannelIs',
          label: '来源渠道',
          type: 'select',
          config: {
            options: customerSource
          }
        },
        {}
      ]
    },
    /* 表格排序 */
    sorter: {
      /* 可排序列 */
      sortableColumns: ['putInTime'],
      /* 默认排序 */
      defaultSort: '-putInTime'
    },
    actions: [],
    columns: [
      {
        key: 'name',
        title: '客户姓名',
        width: '150px',
      },
      // {
      //   key: 'userLabelList',
      //   title: '客户标签',
      //   width: 280,
      //   type: 'color',
      //   config: {
      //     textkey: 'content',
      //     colorkey: 'color'
      //   }
      // },
      {
        key: 'institutions.name',
        title: '所属机构',
        width: '150px',
      },
      {
        key: 'phoneNumber',
        title: '手机号',
        width: '150px',
      },
      {
        key: 'putInTime',
        title: '移入公海时间',
        type: 'datetime',
        width: '150px',
      },
      {
        key: 'moveInReason',
        title: '移入原因',
        width: '150px',
      },
      {
        key: 'sourceChannel',
        title: '来源渠道',
        width: '150px',
      },
      {
        key: 'attribution.name',
        title: '最后归属人',
        width: '150px',
      },

      {
        key: 'followUpWithPeople.name',
        title: '最后跟进人',
        width: '150px',
      },
      {
        key: 'institutionsFollowUp.followUpTime',
        title: '最后跟进时间',
        width: '150px',
        type: 'datetime'
      },
      {
        key: 'institutionsFollowUp.followUpContent',
        title: '最后跟进情况',
        width: '150px',
        ellipsis: true
      },
      {
        key: 'name',
        title: '操作',
        type: 'actions',
        fixed: 'right',
        width: document.documentElement.clientWidth <= 768 ? '50px' : '80px'
      }
    ],
    rowActions: [
      {
        key: 'detail',
        text: '详情',
        type: 'blank',
        permission: '/agency/highSeas/details',
        config: {
          title: '客户详情',
          component: () => import("./views/highSeasDetail")
        }
      },
    ]
  }
}
