<template>
  <div class="inline">
    <span v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" @click="handleUrlAction(action.options)"
        :disabled="disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" @click="handleUrlAction(action.options, selectedRows)"
        :class="{ 'text-danger': action.config.color === 'danger' }" :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>
  </div>
</template>

<script>
import { getProfile } from "@/utils/session";
import { shallowRef } from "vue";
import { toRaw } from '@vue/reactivity';
import dayjs from "dayjs";
export default {
  name: "UrlAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'filterForm'],
  data() {
    return {
      drawerVisible: false,
      components: null
    }
  },
  computed: {
    config() {
      return this.action.config;
    },
  },

  methods: {
    handleUrlAction(action, rows) {
      const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
      const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
      if (action.params === true) {
        const listForm = this.filterForm;
        Object.keys(listForm).forEach(key => {
          const value = listForm[key];
          if (Array.isArray(value) && toRaw(value[0]).$d) {
            const onOrAfter = dayjs(toRaw(value[0]).$d).format('YYYY-MM-DD') + ' 00:00:00';
            const onOrBefore = dayjs(value[1].$d).format('YYYY-MM-DD') + ' 23:59:59';
            url.searchParams.append(`${key}OnOrAfter`, onOrAfter);
            url.searchParams.append(`${key}OnOrBefore`, onOrBefore);
          } else {
            url.searchParams.append(key, listForm[key]);
          }
          
          // if (value != null && value.length !== 0) {
          //   url.searchParams.append(key, listForm[key]);
          // }
        });
        if(this.listFilterKey) {
          url.searchParams.append('quickCheck', this.listFilterKey);
        }
      }
      if (action.newPage) {
        window.open(url.toString());
      } else {
        window.location.href = url.toString();
      }
    },
    async openDrawer() {
      getProfile()
        .then(async (result) => {
          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.drawerVisible = true;
          if (this.components == null) {
            this.components = shallowRef((await this.config.component()).default);
          }
        })
    },


    closeDrawer() {
      this.drawerVisible = false;
    },
    closeAndFetch() {
      this.$emit('change');
      this.drawerVisible = false;
    }

  }
}
</script>
