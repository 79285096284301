
export default {
  key: '/system/optionSet',
  title: '选项配置',
  type: 'table',
  permission: '/option/config/list',
  config: {
    dataUrl: '/option/config/list',
    filter: {
      controls: [
        {
          key: 'optionName',
          label: '字段名',
          type: 'text'
        },
      ]
    },

    actions: [
      {
        key: 'detail',
        text: '新建选项',
        type: 'blank',
        permission: '/option/config/add',
        config: {
          title: '新建选项',
          component: () => import("./views/addOption.vue")
        }
      },
    ],

    columns: [
      {
        key: 'optionName',
        width: '100px',
        title: '字段名'
      },
      {
        key: 'optionValue',
        width: '100px',
        title: '字段值'
      },
      {
        key: 'childrenList',
        width: '100px',
        title: '子项',
      },
      {
        key: 'sort',
        width: '100px',
        title: '排序'
      },
      {
        key: 'name',
        title: '操作',
        fixed: 'right',
        type: 'actions',
        width: '84px'
      }
    ],
    /* 表格排序 */
    sorter: {
      /* 默认排序 */
      defaultSort: ''
    },
    /* 表格行操作 */
    rowActions: [
      {
        key: 'detail',
        text: '编辑',
        type: 'blank',
        permission: '/option/config/update',
        config: {
          title: '编辑',
          component: () => import("./views/editOption.vue")
        }
      },
      {
        key: 'lock',
        text: '启用',
        type: 'confirm',
        permission: '/option/config/enable',
        disabled({ selectedRows }) {
          return selectedRows[0].status === 0;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/option/config/enable'
        }
      },
      {
        key: 'unlock',
        text: '停用',
        type: 'confirm',
        permission: '/option/config/disable',
        disabled({ selectedRows }) {
          return selectedRows[0].status === 1;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/option/config/disable'
        }
      },
      {
        key: 'lock',
        text: '删除',
        type: 'confirm',
        permission: '/option/config/delete',
        disabled: ({ selectedRows }) => {
          return true;
        },
        config: {
          color: 'danger',
          submitColor: 'danger',
          submitUrl: '/option/config/delete'
        }
      },
    ]
  }
};
