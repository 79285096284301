
export default {
  key: '/college/ranking',
  title: '排名',
  permission: '/ranking/list',
  config: {
    component: () => import("./views/ranking"),
    actions: [
      {
        text: '编辑介绍',
        permission: '/ranking/desc/update',
      },
      {
        text: '新增排名',
        permission: '/ranking/sort/add',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/ranking/sort/update',
      }
    ]
  }

}
