export default {
  key: '/college/collegeMajor',
  title: '专业',
  permission: '/professionalCollegeRelation/list',
  permissionTit: '专业',
  config: {
    component: () => import("./views/majorDetail"),
    actions: [
      {
        text: '新建专业',
        permission: '/professionalCollegeRelation/add',
      }
    ],
    rowActions: [
      {
        text: '编辑',
        permission: '/professionalCollegeRelation/update',
      },
      {
        text: '删除',
        permission: '/professionalCollegeRelation/delete',
      }
    ]
  }
} 