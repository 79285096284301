<template>
  <div class="inline">
    <span @click="openDrawer" v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" :disabled="disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" :class="{ 'text-danger': action.config.color === 'danger' }"
        :disabled="!disabledValue" type="link" style="padding: 0;">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.subText ? action.subText(selectedRows) : action.text }}
        <!-- {{ action.text }} -->
      </a-button>
    </span>


    <a-drawer :bodyStyle="{padding: $isMobile ? '8px' : ''}" :title="action.config.title" placement="right" :closable="true" :destroy-on-close="true" :width="$isMobile ? 320 : 800"
      :visible="drawerVisible" @close="closeDrawer">
      <component v-if="components != null" :is="components" :selected-rows="selectedRows" @close="closeAndFetch" />
    </a-drawer>
    <a-modal v-model:open="openModal" :title="modalTitle">
      <div v-html="modalContent"></div>
      <template #footer>
        <div style="text-align: center;">
          <a-button @click="closeModal">知道了</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getProfile } from "@/utils/session";
import { shallowRef } from "vue";
export default {
  name: "BlankAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue', 'tableData'],
  data() {
    return {
      drawerVisible: false,
      components: null,
      openModal: false,
      modalTitle: '',
      modalContent: '',
    }
  },
  computed: {
    config() {
      return this.action.config;
    },

    // disabled() {
    //   if (this.action.disabled == null) {
    //     return false;
    //   }

    //   if (typeof this.action.disabled === 'function') {
    //     return this.action.disabled({
    //       selectedRows: this.selectedRows
    //     });
    //   }
    //   return this.action.disabled;
    // },

    // props() {
    //   if (this.type === 'button') {
    //     return {
    //       is: 'a-button',
    //       disabled: this.disabled,
    //       type: this.action.config.color,
    //       icon: this.action.icon
    //     };
    //   }
    //   return {
    //     is: 'a',
    //     class: { 'text-danger': this.action.config.color === 'danger' },
    //     disabled: this.disabled
    //   };
    // }
  },

  methods: {
    closeModal() {
      this.openModal = false;
    },
    async openDrawer() {
      if (this.action.isSelected && (this.selectedRows.length == 0 || this.selectedRows[0] == undefined)) {
        this.$message.error('请勾选要编辑的内容');
        return
      }
      if (this.action.determine) {
        let modalObj = this.action.modalObj(this.selectedRows, this.tableData);
        if (modalObj.showMOdal) {
          this.modalTitle = modalObj.title;
          this.modalContent = modalObj.content;
          this.openModal = true;
          return
        }
      };
      getProfile()
        .then(async (result) => {

          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }
          this.drawerVisible = true;
          if (this.components == null) {
            this.components = shallowRef((await this.config.component()).default);
          }
        })
    },


    closeDrawer() {
      this.$emit('change');
      this.drawerVisible = false;
    },
    closeAndFetch() {
      this.$emit('change');
      this.drawerVisible = false;
    }

  }
}
</script>
