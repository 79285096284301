export default {
    key: '/newCustomer/highSeas',
    title: '客户公海',
    permission: '/agency/institutionsCustomerHighSeas/list',
    permissionTit: '留服客户公海-列表',
    config: {
        component: () => import("./tabviews/highSeasDetail"),
        actions: [
            {
                text: '留服客户公海-详情',
                permission: '/agency/institutionsCustomerHighSeas/details',
            },
            {
                text: '留服客户公海-领取客资',
                permission: '/agency/institutionsCustomerHighSeas/receiveCustomerResources',
            },
            {
                text: '留服客户公海-分配客资',
                permission: '/agency/institutionsCustomerHighSeas/allocationOfCustomerCapital',
            },
            {
                text: '机构客户公海-列表',
                permission: '/agency/highSeas/list',
            },

            {
                text: '机构客户公海-详情',
                permission: '/agency/highSeas/details',
            },

            {
                text: '未领取客资-查看列表',
                permission: '/retention/retentionClients/list',
            },
            {
                text: '未领取客资-领取客资',
                permission: '/retention/retentionClients/receiveCustomer',
            },
            {
                text: '未领取客资-分配客资',
                permission: '/retention/retentionClients/assignCustomers',
            },
            {
                text: '未领取客资-删除客资',
                permission: '/retention/retentionClients/delete',
            },
            // {
            //     text: '留服客户公海',
            //     permission: '/agency/institutionsFollowUp/list',
            // },
        ]
    }

}

