import toBeAllocated from "./toBeAllocated";
import institutionsCustomer from "./institutionsCustomer";
import followup from "./followup";
import highSeas from "./highSeas";
// import visitCustomer from "./visitCustomer"
export default [
  toBeAllocated,
  institutionsCustomer,
  followup,
  highSeas,
  // visitCustomer
];
